import { FC, memo, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react'
import { chevronBack, chevronForward } from 'ionicons/icons'

interface SimplePaginationProps {
  items: any[]
  size: number
  onOffsetChange: (offset: number) => void
  defaultOffset?: number
}
const SimplePagination: FC<SimplePaginationProps> = ({ defaultOffset = 0, items, size, onOffsetChange }) => {
  const [offset, setOffset] = useState(defaultOffset)

  useEffect(() => {
    onOffsetChange(offset)
  }, [offset])

  return (
    <IonItem>
      <IonButtons slot='start'>
        <IonButton disabled={offset === 0} onClick={() => setOffset(offset - size)}>
          <IonIcon icon={document.documentElement.dir === 'rtl' ? chevronForward : chevronBack} />
        </IonButton>
      </IonButtons>
      <IonButtons slot='end'>
        <IonButton onClick={() => setOffset(offset + size)} disabled={items.length < size}>
          <IonIcon icon={document.documentElement.dir === 'rtl' ? chevronBack : chevronForward} />
        </IonButton>
      </IonButtons>
    </IonItem>
  )
}

export default memo(SimplePagination)
