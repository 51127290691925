import { memo } from 'react'

const Enamad = () => {
  return (
    <a
      referrerPolicy='origin'
      target='_blank'
      href='https://trustseal.enamad.ir/?id=357563&amp;Code=CGCuDCzrGWgwJ9QcPZx8'>
      <img
        referrerPolicy='origin'
        src='https://Trustseal.eNamad.ir/logo.aspx?id=357563&amp;Code=CGCuDCzrGWgwJ9QcPZx8'
        alt=''
        style={{ cursor: 'pointer' }}
        id='CGCuDCzrGWgwJ9QcPZx8'
      />
    </a>
  )
}

export default memo(Enamad)
