import { FC, memo, useEffect, useState } from 'react'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { t } from 'i18next'
import currencySign from '../../components/util/currencySign'
import { FactorItem } from '../../models/mfactor/FactorItem'

interface OwnProps {
  factorItems: FactorItem[]
}
const FactorTotal: FC<OwnProps> = ({ factorItems }) => {
  const [price, setPrice] = useState<number>()
  const [currencies, setCurrencies] = useState<Set<string>>(new Set())

  const calcPrice = () => {
    setPrice(factorItems.reduce((a: any, c: any) => a + c.qty * c.price, 0))
  }

  const hasMultiCurrency = () => {
    const currencySet = new Set<string>()
    factorItems.map((fi) => {
      if (fi.currency) currencySet.add(fi.currency)
    })
    setCurrencies(currencySet)
    return currencySet.size > 1
  }

  useEffect(() => {
    calcPrice()
    hasMultiCurrency()
  }, [factorItems])

  return (
    <IonItem>
      <IonLabel color={currencies?.size > 1 ? 'danger' : ''}>{t<string>('Total price')}</IonLabel>
      <IonText color={currencies?.size > 1 ? 'danger' : ''} style={{ paddingLeft: '50px' }} className='dir-ltr'>
        <span>{currencies.size === 1 && currencySign([...currencies][0])}</span>
        {price?.toLocaleString(navigator.language)}
        <span className='ion-padding'>{currencies?.size > 1 && `${Array.from(currencies).join(' & ')}`}</span>
      </IonText>
    </IonItem>
  )
}

export default memo(FactorTotal)
