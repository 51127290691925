import { failure } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { setRefreshPersonList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { MFactorDbService } from '../../services/MFactorDbService'
import PersonAddField from './PersonAddField'
import { IonButton, IonButtons, IonCol, IonItem } from '@ionic/react'
import { IonReorder, IonReorderGroup, IonSegment } from '@ionic/react'
import { IonSegmentButton, IonText, ItemReorderEventDetail } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonInput, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { t } from 'i18next'
import { add, business, personCircle } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  id?: string
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  lang?: string
  currency?: string
  hand: 'right' | 'left'
  app?: App
  refreshPersonList: boolean
}
interface DispatchProps {
  setRefreshPersonList: typeof setRefreshPersonList
}
interface PersonEditModalProps extends OwnProps, StateProps, DispatchProps {}
const PersonEditModal: FC<PersonEditModalProps> = ({
  id,
  onDismiss,
  currency,
  lang,
  hand,
  app,
  refreshPersonList,
  setRefreshPersonList,
}) => {
  const database = MFactorDbService.Instance.db
  const [person, setPerson] = useState<Person | any>({
    id,
    personType: 'REAL',
  })
  const [fields, setFields] = useState<any[]>([])
  const [inprogress, setInprogress] = useState(false)
  const [toast] = useIonToast()

  const findById = async () => {
    if (id) {
      const person = await database
        .get<Person>('person')
        .find(id)
        .catch(() => null)
      if (person) {
        setPerson(person.workaroundSetModel())
        if (person.detail) setFields(JSON.parse(person.detail)?.fields || [])
      }
    }
  }

  const store = async () => {
    setInprogress(true)
    if (app?.publicId) {
      await database.write(async () => {
        if (person?._raw?.id) {
          const existed: Person | null = await database
            .get<Person>('person')
            .find(person._raw?.id)
            .catch(() => null)
          if (existed) {
            await existed.update(() => {
              existed.personType = person.personType
              existed.name = fixKafYe(person.name) || person.name
              existed.nationalId = person.nationalId
              fields.map((f: any, idx: number) => {
                // Re-index
                f.r = idx
                return f
              })
              if (existed.detail) {
                existed.detail = JSON.stringify({
                  ...JSON.parse(existed.detail),
                  fields,
                })
              } else {
                existed.detail = JSON.stringify({ fields })
              }
              existed.workaroundSetRaw()
            })
            return existed
          }
        } else {
          const pe: Person = await database
            .get<Person>('person')
            .create((pe) => {
              pe.appPublicId = app.publicId
              pe.personType = person.personType
              pe.name = fixKafYe(person.name) || person.name
              pe.nationalId = person.nationalId
              pe.detail = JSON.stringify({ fields })
              pe.workaroundSetRaw()
            })
          return pe
        }
      })
      onDismiss(person, 'edited')
      setPerson(undefined)
      await setRefreshPersonList(!refreshPersonList)
    } else {
      failure('Please select your app first', toast)
    }
    setInprogress(false)
  }

  const addToDetail = (key: string, value: string) => {
    setFields((fields) => [...fields, { r: fields.length, k: key, v: value }])
  }

  const setDetailKeyValue = (key: string, value: string) => {
    const elem = fields.find((f: any) => f.k === key)
    elem.v = value
    fields.map((x: any) => (x.k === key ? { ...elem } : x))
    setFields(fields)
  }

  const handleReorder = (evt: CustomEvent<ItemReorderEventDetail>) => {
    const movedItem = fields.splice(evt.detail.from, 1)[0]
    fields.splice(evt.detail.to, 0, movedItem)
    setFields(fields)
    evt.detail.complete()
  }

  useEffect(() => {
    findById()
  }, [])

  useEffect(() => {}, [fields])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              {t<string>('Cancel')}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {t<string>(person?._raw?.id ? 'Edit person' : 'New person')}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSegment
          value={person?.personType}
          onIonChange={(e) =>
            setPerson({ ...person, personType: e.detail.value })
          }
        >
          <IonSegmentButton value='REAL'>
            <IonIcon icon={personCircle} />
          </IonSegmentButton>
          <IonSegmentButton value='COMPANY'>
            <IonIcon icon={business} />
          </IonSegmentButton>
        </IonSegment>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              value={person?.name}
              onIonInput={(e) => setPerson({ ...person, name: e.detail.value })}
              label={t('Name in', { personType: t(person?.personType) })}
              placeholder={t('Name')}
              required={true}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              value={person?.nationalId}
              onIonInput={(e) =>
                setPerson({ ...person, nationalId: e.detail.value })
              }
              label={t('National Id')}
              placeholder={t('National Id')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
          {fields.map((f: any, idx: number) => (
            <IonItem
              key={`${idx}_${f.k}_${f.v}`}
              className='ion-padding-horizontal'
            >
              <IonReorder slot='start' />
              <IonInput
                value={f.v}
                onIonInput={(e: any) => setDetailKeyValue(f.k, e.detail.value)}
                placeholder={f.k}
                label={f.k}
              />
              <IonText>{f.r}</IonText>
            </IonItem>
          ))}
        </IonReorderGroup>
        <div className='ion-padding-horizontal'>
          <PersonAddField
            onAdd={(key: string, value: string) => addToDetail(key, value)}
          />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton
              onClick={() => store()}
              disabled={inprogress}
              color='primary'
              fill='outline'
            >
              {!inprogress && <IonIcon icon={add} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    currency: state.user.currency,
    hand: state.user.hand,
    app: state.user.app,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  mapDispatchToProps: {
    setRefreshPersonList,
  },
  component: React.memo(PersonEditModal),
})
