import TaakSDK from 'taak-sdk'
import { TaakResponse } from 'taak-sdk/dist/taak-response'
import { WebPushSubscribeCommand } from 'taak-sdk/dist/web-push/types'
import React, { FC, useState } from 'react'
import { osName, browserName, browserVersion } from 'react-device-detect'
import { IonButton, useIonToast } from '@ionic/react'
import { t } from 'i18next'
import { log } from '../../components/util/Log'
import { failure, success } from '../../components/util/Toast'
import { urlBase64ToUint8Array } from '../../components/util/WebPush'
import { connect } from '../../data/connect'
import { User } from '../../models/User'

interface OwnProps {
  onSuccess?: (data: any) => void
}
interface StateProps {
  webPush?: boolean
  user: User
}
interface WebPushSubscribeButtonProps extends OwnProps, StateProps {}

const WebPushSubscribeButton: FC<WebPushSubscribeButtonProps> = ({ webPush, onSuccess, user }) => {
  const [subscribing, setSubscribing] = useState(false)
  const [presentToast] = useIonToast()
  const taakClient = new TaakSDK({
    apiKey: import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    debug: false,
    local: false,
  })

  const checkSubscribe = () => {
    setSubscribing(true)
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      // Get the push notification subscription object
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then(function (subscription: PushSubscription | null) {
          // If this is the user's first visit we need to set up
          // a subscription to push notifications
          if (!subscription) {
            subscribe()
            return
          }

          // Update the server state with the new subscription
          sendSubscriptionToServer(subscription)
        })
        .catch(function (err) {
          // Handle the error - show a notification in the GUI
          console.warn('Error during getSubscription()', err)
          setSubscribing(false)
        })
        .finally(function () {
          setSubscribing(false)
        })
    })
  }

  const subscribe = () => {
    setSubscribing(true)
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(TaakSDK.DEFAULT_WEB_PUSH_SERVER_PUBLIC_KEY),
        })
        .then(function (subscription: PushSubscription) {
          setSubscribing(false)
          // Update the server state with the new subscription
          return sendSubscriptionToServer(subscription)
        })
        .catch(function (e) {
          setSubscribing(false)
          if (Notification.permission === 'denied') {
            console.warn('Permission for Notifications was denied')
          } else {
            console.error('Unable to subscribe to push.', e)
          }
        })
    })
  }

  const sendSubscriptionToServer = async (subscription: PushSubscription) => {
    setSubscribing(true)
    log('Subscribing', JSON.stringify(subscription))
    const subscriptionObject = JSON.parse(JSON.stringify(subscription))

    const cmd: WebPushSubscribeCommand = {
      endpoint: subscriptionObject?.endpoint,
      key: subscriptionObject?.keys?.p256dh,
      auth: subscriptionObject?.keys?.auth,
      userId: user.sub,
      deviceId: `${osName} :: ${browserName} :: ${browserVersion}`,
    }
    const res: TaakResponse = await taakClient.subscribeWebPush(cmd)
    if (res.status === 201) {
      success('Success subscribing.', presentToast)
      if (!!onSuccess) onSuccess(res.data)
    } else {
      failure(`Failure ${res.status}`, presentToast)
    }
    setSubscribing(false)
  }

  return (
    <IonButton onClick={checkSubscribe} disabled={!webPush || subscribing}>
      {t<string>('Subscribe')}
    </IonButton>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    webPush: state.data.webPush,
    user: state.user.user,
  }),
  component: React.memo(WebPushSubscribeButton),
})
