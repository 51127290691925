import { connect } from '../../data/connect'
import AppContainer from '../AppContainer'
import WebPushToggle from '../account/WebPushToggle'
import WebPushList from './WebPushList'
import WebPushSubscribe from './WebPushSubscribe'
import WebPushSubscribeButton from './WebPushSubscribeButton'
import { IonChip, IonCol, IonGrid, IonRow } from '@ionic/react'
import { t } from 'i18next'
import React, { FC, useState } from 'react'

interface OwnProps {}
interface StateProps {
  webPush?: boolean
}
interface WebPushProp extends OwnProps, StateProps {}
const WebPush: FC<WebPushProp> = ({ webPush }) => {
  const [reloadWebPush, setReloadWebPush] = useState(false)

  return (
    <AppContainer id='web-push-page' title={t('Web push notifications')}>
      <WebPushSubscribe onSuccess={() => setReloadWebPush(!reloadWebPush)} />
      <WebPushList reload={reloadWebPush} />
    </AppContainer>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    webPush: state.data.webPush,
  }),
  component: React.memo(WebPush),
})
