import { Database } from '@nozbe/watermelondb'
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import { error, log } from '../../components/util/Log'
import schemaMigrations from '../../models/gtin/Migrations'
import Product from '../../models/gtin/Product'
import appSchema from '../../models/gtin/Schema'

const gtinAdapter = new LokiJSAdapter({
  dbName: 'ProductDB',
  schema: appSchema,
  migrations: schemaMigrations,
  useWebWorker: false,
  useIncrementalIndexedDB: true,
  extraLokiOptions: {
    autosave: true,
  },
  onQuotaExceededError: (err) => {
    error('WatermelonOnQuotaExceededError', err)
  },
  onSetUpError: (err) => {
    error('WatermelonOnSetupError', err)
  },
  extraIncrementalIDBOptions: {
    onDidOverwrite: () => {
      log('WatermelonOnDidOverwrite')
    },
    onversionchange: () => {
      log('WatermelonOnVersionChange')
    },
  },
})

export const productdb: Database = new Database({
  adapter: gtinAdapter,
  modelClasses: [Product],
})
