import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import enTranslations from './locales/en/translations.json'
import faTranslations from './locales/fa/translations.json'

export const resources = {
  en: {
    translation: enTranslations,
  },
  fa: {
    translation: faTranslations,
  },
}

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: window.document.documentElement.lang,
  resources,
})

i18next.languages = ['fa', 'en']

export default i18next
