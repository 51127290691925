import axios, { AxiosInstance, AxiosResponse } from 'axios'

export class TranslateService {
  private static translateService: TranslateService | undefined
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  public async toPersian(englishText: string): Promise<AxiosResponse> {
    return this.axiosInstance.post(
      '/translate',
      {
        q: englishText,
        source: 'en',
        target: 'fa',
        format: 'text',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  public async toEnglish(persianText: string): Promise<AxiosResponse> {
    return this.axiosInstance.post(
      '/translate',
      {
        q: persianText,
        source: 'fa',
        target: 'en',
        format: 'text',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  private static buildTranslateService() {
    const axiosInstance = axios.create({
      baseURL: 'https://translate.taakcloud.com',
    })
    return new TranslateService(axiosInstance)
  }

  public static get Instance(): TranslateService {
    if (!this.translateService) this.translateService = this.buildTranslateService()
    return this.translateService
  }
}
