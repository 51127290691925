import React, { useState } from 'react'
import { IonIcon, IonInput, IonLabel, IonSpinner, useIonToast } from '@ionic/react'
import { IonButton, IonList } from '@ionic/react'
import { IonTextarea } from '@ionic/react'
import { save } from 'ionicons/icons'
import { t } from 'i18next'
import { AxiosResponse } from 'axios'
import { failure, success } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { User } from '../../models/User'
import { GtinService } from '../../services/GtinService'
import PageContainer from '../PageContainer'

interface OwnProps {}
interface StateProps {
  user?: User
}
interface DispatchProps {}

interface SupportProps extends OwnProps, StateProps, DispatchProps {}

const Support: React.FC<SupportProps> = ({ user }) => {
  const [message, setMessage] = useState('')
  const [sender, setSender] = useState(user?.email)
  const [toast] = useIonToast()
  const [inprogress, setInprogress] = useState(false)

  const send = async (e: React.FormEvent) => {
    e.preventDefault()
    setInprogress(true)
    try {
      const res: AxiosResponse = await GtinService.Instance.post(
        `/v1/support/message`,
        { message, sender: sender || user?.email },
        {},
        false
      )
      if (res?.status === 200) {
        setMessage('')
        success('Your support request has been sent', toast)
      }
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setInprogress(false)
  }

  return (
    <PageContainer id='support-page' title={t<string>('Support')} isPrivate={false}>
      <div className='login-logo'>
        <img src='assets/img/appicon.svg' alt='Ionic logo' />
      </div>

      <form noValidate onSubmit={send}>
        <IonList className='ion-padding'>
          <IonLabel className='required'>{t<string>('Enter your support message below')}</IonLabel>
          <IonTextarea
            disabled={inprogress}
            placeholder={`${t<string>('Type here')}...`}
            name='message'
            value={message}
            spellCheck={false}
            autocapitalize='off'
            rows={6}
            onIonInput={(e: any) => setMessage(e.detail.value!)}
            required></IonTextarea>
          <IonInput
            value={sender}
            disabled={inprogress}
            onIonInput={(e: any) => setSender(e.detail.value)}
            placeholder={t<string>('Your email')}
          />
        </IonList>

        <IonButton type='submit' expand='block' disabled={!message || inprogress}>
          {inprogress && <IonSpinner slot='start' />}
          {!inprogress && <IonIcon icon={save} slot='start' />}
          {t<string>('Submit')}
        </IonButton>
      </form>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  component: React.memo(Support),
})
