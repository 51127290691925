import React from 'react'
import { t } from 'i18next'
import PageContainer from '../../PageContainer'
import './About.scss'

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  return (
    <PageContainer id='about-page' isPrivate={false} padding={false}>
      <div className='about-header'>
        <div className='about-image seattle' style={{ opacity: '1' }}></div>
      </div>
      <div className='about-info'>
        <h3 className='ion-padding-top ion-padding-start'>{t<string>('About')}</h3>

        <p className='ion-padding-start ion-padding-end'>
          {t<string>('AboutP1')} {t<string>('AboutP1_2')} {t<string>('AboutP1_3')} {t<string>('AboutP1_4')}
        </p>
      </div>
    </PageContainer>
  )
}

export default React.memo(About)
