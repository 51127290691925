import { TokenResponse } from '@openid/appauth'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { TResponse } from '../components/util/TResponse'
import { Auth } from './AuthService'

export class AppApiService {
  private static appApiService: AppApiService | undefined
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  public async get(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    const validToken: TokenResponse = await Auth.Instance.getValidToken()
    defaultConfig.headers = {
      ...defaultConfig.headers,
      Authorization: `Bearer ${validToken.accessToken}`,
      'X-TAAK-API-KEY': import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    }
    return this.axiosInstance.get(path, defaultConfig)
  }

  public async post(path: string, data: any, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const defaultConfig = !!config ? config : {}
      const validToken: TokenResponse = await Auth.Instance.getValidToken()
      defaultConfig.headers = {
        ...defaultConfig.headers,
        Authorization: `Bearer ${validToken.accessToken}`,
        'X-TAAK-API-KEY': import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
      }
      const res: AxiosResponse = await this.axiosInstance.post(path, data, defaultConfig)
      return { status: res.status, data: res.data }
    } catch (err: any) {
      if (err.response) {
        return { status: err.response.status, data: err.response.data }
      } else if (err.request) {
        return { status: -1 }
      } else {
        return { status: 0 }
      }
    }
  }

  public async put(path: string, data: any, config?: AxiosRequestConfig, anonymous = false): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    if (!anonymous) {
      const validToken: TokenResponse = await Auth.Instance.getValidToken()
      defaultConfig.headers = {
        ...defaultConfig.headers,
        Authorization: `Bearer ${validToken.accessToken}`,
        'X-TAAK-API-KEY': import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
      }
    }
    return this.axiosInstance.put(path, data, defaultConfig)
  }

  public async delete(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    const validToken: TokenResponse = await Auth.Instance.getValidToken()
    defaultConfig.headers = {
      ...defaultConfig.headers,
      Authorization: `Bearer ${validToken.accessToken}`,
      'X-TAAK-API-KEY': import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    }
    return this.axiosInstance.delete(path, defaultConfig)
  }

  private static buildApiInstance() {
    const axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_APP_BASE_URI,
    })
    return new AppApiService(axiosInstance)
  }

  public static get Instance(): AppApiService {
    if (!this.appApiService) {
      this.appApiService = this.buildApiInstance()
    }

    return this.appApiService
  }
}
