import { FC, memo } from 'react'
import { connect } from '../data/connect'
import Product from '../models/gtin/Product'
import './ProductName.scss'
import { productNameOneLine } from './productNameOneLine'

interface OwnProps {
  product: Product
  wrapText?: boolean
}
interface StateProps {
  lang?: string
}
interface ProductNameProp extends OwnProps, StateProps {}
const ProductName: FC<ProductNameProp> = ({ product, lang, wrapText = false }) => {
  const resolveDirection = (): string => {
    if (lang === 'fa' && product.nameFa) return 'dir-rtl'
    else return 'dir-ltr'
  }

  return <div className={resolveDirection() + (wrapText ? ' wrap-text' : '')}>{productNameOneLine(product, lang)}</div>
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
  }),
  component: memo(ProductName),
})
