import { FC, memo, useEffect, useState } from 'react'
import { IonItem, IonLabel, IonSearchbar, useIonModal, useIonToast } from '@ionic/react'
import { t } from 'i18next'
import { AxiosResponse } from 'axios'
import SimplePagination from '../../components/SimplePagination'
import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { AppApiService } from '../../services/AppApiService'
import PageContainer from '../PageContainer'
import AdminUserEditModal from './AdminUserEditModal'
import { UserRepresentation } from './UserRepresentation'

interface AdminUsersProps {}
const AdminUsers: FC<AdminUsersProps> = () => {
  const [users, setUsers] = useState<UserRepresentation[]>([])
  const [search, setSearch] = useState<string>()
  const [first, setFirst] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [toast] = useIonToast()
  const [userRepresentation, setUserRepresentation] = useState<UserRepresentation>()
  const [present, dismiss] = useIonModal(AdminUserEditModal, {
    userRepresentation,
    onDismiss: (data: string, role: string) => {
      dismiss(data, role)
      fetchUsers()
    },
  })

  const fetchUsers = async () => {
    setFetching(true)
    try {
      const res: AxiosResponse = await AppApiService.Instance.get(
        `/v1/admin/users/${!!search ? search : ''}/${first}/10`
      )
      if (res.status === 200) setUsers(res.data)
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setFetching(false)
  }

  useEffect(() => {
    const tout = setTimeout(() => {
      fetchUsers()
    }, 300)
    return () => clearTimeout(tout)
  }, [first, search])

  return (
    <PageContainer id='admin-users' title={t('Users')}>
      <IonSearchbar placeholder={t('Users')} onIonInput={(e: any) => setSearch(e.detail.value)} debounce={500} />
      {users.map((usr: UserRepresentation) => (
        <IonItem
          button={true}
          key={usr.id}
          onClick={() => {
            setUserRepresentation(usr)
            setTimeout(() => {
              present()
            }, 300)
          }}>
          <IonLabel>{usr.username}</IonLabel>
        </IonItem>
      ))}
      <SimplePagination
        items={users}
        defaultOffset={first}
        size={10}
        onOffsetChange={(offset: number) => setFirst(offset)}
      />
    </PageContainer>
  )
}

export default memo(AdminUsers)
