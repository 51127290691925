import Desk from '../pages-mfactor/desk/Desk'
import Account from '../pages/account/Account'
import Login from '../pages/public/login/Login'
import LoginRedirect from '../pages/public/login/LoginRedirect'
import LogoutRedirect from '../pages/public/login/LogoutRedirect'
import { IonRouterOutlet } from '@ionic/react'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

const MFactorRoutes = () => {
  return (
    <IonRouterOutlet id='mFactor'>
      <Route path='/' component={Desk} exact />
      <Route path='/account' component={Account} />
      <Route path='/login' component={Login} />
      <Route path='/authcallback' component={LoginRedirect} />
      <Route path='/endsession' component={LogoutRedirect} exact />
      {/* <Switch>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch> */}
    </IonRouterOutlet>
  )
}

export default React.memo(MFactorRoutes)
