const mainSlot = (hand: 'right' | 'left', dir: string) => {
  if (hand === 'right' && dir === 'rtl') return 'start'
  if (hand === 'right' && dir === 'ltr') return 'end'
  if (hand === 'left' && dir === 'rtl') return 'end'
  if (hand === 'left' && dir === 'ltr') return 'start'
}

const minorSlot = (hand: 'right' | 'left', dir: string) => {
  if (hand === 'right' && dir === 'rtl') return 'end'
  if (hand === 'right' && dir === 'ltr') return 'start'
  if (hand === 'left' && dir === 'rtl') return 'start'
  if (hand === 'left' && dir === 'ltr') return 'end'
}

export { mainSlot, minorSlot }
