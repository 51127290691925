import React, { FC, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IonButton, IonButtons, IonChip, IonFooter } from '@ionic/react'
import { IonIcon, IonItem, IonLabel, IonToolbar, useIonToast } from '@ionic/react'
import { clipboard } from 'ionicons/icons'
import { t } from 'i18next'
import { AxiosResponse } from 'axios'
import { failure, info, success } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { ApiKey } from '../../models/ApiKey'
import { App } from '../../models/App'
import { AppApiService } from '../../services/AppApiService'

interface OwnProps {
  onClose: (apiKey?: any) => void
}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface ApiKeyEditProps extends OwnProps, StateProps, DispatchProps {}

const ApiKeyEdit: FC<ApiKeyEditProps> = ({ onClose, app }) => {
  const [present] = useIonToast()
  const [item, setItem] = useState<ApiKey>()
  const [wait, setWait] = useState(false)

  const generate = async () => {
    setWait(true)
    try {
      const res: AxiosResponse | any = await AppApiService.Instance.post(`/v1/api-keys/${app?.publicId}`, {})
      if (res.status === 201) {
        success('Success creating Api key.', present)
        setItem(res.data)
      } else {
        failure(`Failure ${res.status}`, present)
      }
    } catch (err: any) {
      failure(err.message, present)
    }
    setWait(false)
  }

  return (
    <div className='flex-container flex-column flex-space-around ion-padding' style={{ height: '100vh' }}>
      <IonChip color={'tertiary'}>Please copy generated API KEY from below, you can't access it again.</IonChip>
      {!!item?.keyValue && (
        <CopyToClipboard text={item.keyValue} onCopy={() => info('Copied!', present)}>
          <IonItem button>
            <IonLabel className='ion-text-wrap'>{item.keyValue}</IonLabel>
            <IonIcon icon={clipboard} />
          </IonItem>
        </CopyToClipboard>
      )}
      <IonFooter>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton onClick={() => onClose(item)}>{t<string>('Close')}</IonButton>
            <IonButton onClick={generate} disabled={wait} color='primary'>
              {t<string>('Generate')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  mapDispatchToProps: {},
  component: React.memo(ApiKeyEdit),
})
