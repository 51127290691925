import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { IonButton, IonCol, IonRow } from '@ionic/react'
import { t } from 'i18next'
import { connect } from '../../../data/connect'
import { setIsAuthenticated } from '../../../data/user/user.actions'
import { Auth } from '../../../services/AuthService'
import PageContainer from '../../PageContainer'
import './Login.scss'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  lastRoute: string
  isAuthenticated: boolean
}
interface DispatchProps {
  setIsAuthenticated: typeof setIsAuthenticated
}
interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const Login: React.FC<LoginProps> = ({ isAuthenticated, history, lastRoute }) => {
  const [signInprogress, setSignInprogress] = useState(false)

  const handleSignIn = async (e: any) => {
    setSignInprogress(true)
    e.preventDefault()
    try {
      await Auth.Instance.signIn()
        .then()
        .catch(() => {})
      setTimeout(() => {
        setSignInprogress(false)
      }, 5000)
    } catch (ignore) {}
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(lastRoute || '/')
    }
  }, [isAuthenticated]) // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      Auth.Instance.getValidToken().catch(() => null)
    }, 300)
  }, [])

  return (
    <PageContainer id='login-page' title='Login' isPrivate={false}>
      <div className='login-logo'>
        <img src='assets/img/appicon.svg' alt='Taak logo' />
      </div>
      <IonRow>
        <IonCol>
          <IonButton expand='block' onClick={handleSignIn} disabled={signInprogress}>
            {t<string>('Login')}
          </IonButton>
        </IonCol>
      </IonRow>
    </PageContainer>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    lastRoute: state.user.lastRoute,
    isAuthenticated: state.user.isAuthenticated,
  }),
  mapDispatchToProps: {
    setIsAuthenticated,
  },
  component: React.memo(withRouter(Login)),
})
