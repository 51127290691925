import currencySign from '../../components/util/currencySign'
import { mainSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import Factor from '../../models/mfactor/Factor'
import { MFactorDbService } from '../../services/MFactorDbService'
import './FactorDeleteButton.scss'
import { IonAlert, IonButton, IonIcon } from '@ionic/react'
import { t } from 'i18next'
import { trashBin } from 'ionicons/icons'
import { FC, memo } from 'react'

interface OwnProps {
  factor: Factor
  onDelete: Function
  disabled?: boolean
}
interface StateProps {
  hand: 'right' | 'left'
}
interface FactorDeleteButtonProps extends OwnProps, StateProps {}
const FactorDeleteButton: FC<FactorDeleteButtonProps> = ({
  factor,
  hand,
  onDelete,
  disabled = false,
}) => {
  const deleteFactor = async () => {
    const fac: Factor = await MFactorDbService.Instance.db
      .get<Factor>('factor')
      .find(factor.id)
    fac.markAsDeletedForReal()
    onDelete(fac)
  }

  const yesButton = () => ({
    text: t('Yes'),
    role: 'destructive',
    cssClass: 'alert-button-delete',
    handler: () => {},
  })

  const noButton = () => ({
    text: t('No'),
    role: 'cancel',
    cssClass: 'alert-button-cancel',
    handler: () => {},
  })

  const buttons = () => {
    return mainSlot(hand, document.documentElement.dir) === 'start'
      ? [yesButton(), noButton()]
      : [noButton(), yesButton()]
  }

  return (
    <>
      <IonButton
        id='present-factor-delete-alert'
        color='danger'
        fill='clear'
        disabled={disabled}
      >
        <IonIcon icon={trashBin} slot='start' />
        {t<string>('Delete')}
      </IonButton>
      <IonAlert
        mode='ios'
        header={t('Delete confirm?')}
        subHeader={`${currencySign(
          factor?.currency
        )} ${factor?.total?.toLocaleString(navigator.language)}`}
        trigger='present-factor-delete-alert'
        cssClass={'taak-alert'}
        buttons={buttons()}
        onDidDismiss={({ detail }) => {
          if (detail.role === 'destructive') {
            deleteFactor()
          } else {
            onDelete(undefined)
          }
        }}
      ></IonAlert>
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
  }),
  component: memo(FactorDeleteButton),
})
