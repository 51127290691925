import { convertToEnglish } from '../../components/util/Number'
import { failure, info } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import {
  setRefreshProductList,
  setSyncing,
} from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Product from '../../models/gtin/Product'
import { FactorItem } from '../../models/mfactor/FactorItem'
import PriceModal from '../../pages-gtin/PriceModal'
import ProductName from '../../pages-gtin/ProductName'
import { productNameOneLine } from '../../pages-gtin/productNameOneLine'
import { GtinDbService } from '../../services/GtinDbService'
import './ProductSelectCard.scss'
import {
  IonBadge,
  IonButton,
  IonChip,
  IonIcon,
  IonSearchbar,
  useIonToast,
} from '@ionic/react'
import { Q } from '@nozbe/watermelondb'
import { t } from 'i18next'
import { sync } from 'ionicons/icons'
import { FC, memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

interface OwnProps {
  factorItemList: FactorItem[]
  addFactorItem: Function
  factorSaving: boolean
}
interface StateProps {
  app?: App
  lang?: string
  currency?: string
  refreshProductList: boolean
  syncing: number
}
interface DispatchProps {
  setSyncing: typeof setSyncing
  setRefreshProductList: typeof setRefreshProductList
}
interface ProductSelectCardProps extends OwnProps, StateProps, DispatchProps {}
const ProductSelectCard: FC<ProductSelectCardProps> = ({
  app,
  addFactorItem,
  refreshProductList,
  factorItemList,
  lang,
  currency,
  factorSaving,
  syncing,
  setSyncing,
  setRefreshProductList,
}) => {
  const [products, setProducts] = useState<Product[]>([])
  const [productListResolved, setProductListResolved] = useState(false)
  const [search, setSearch] = useState<string | null>()
  const [dynamicPrice, setDynamicPrice] = useState()
  const [product, setProduct] = useState<Product>()
  const [priceModalOpen, setPriceModalOpen] = useState(false)
  const [showSyncButton, setShowSyncButton] = useState(false)
  const [toast] = useIonToast()

  const fetchProducts = async () => {
    setProductListResolved(false)
    await GtinDbService.Instance.db.read(async () => {
      let whereClause = []
      whereClause.push(Q.where('app_public_id', app?.publicId || null))
      if (!!search) {
        whereClause.push(
          Q.and(
            Q.or(
              Q.where('name', Q.like(`%${search}%`)),
              Q.where('name_fa', Q.like(`%${fixKafYe(search)}%`)),
              Q.where('price', Q.like(`%${convertToEnglish(search)}%`))
            )
          )
        )
      }
      const prods: Product[] = await GtinDbService.Instance.db
        ?.get<Product>('product')
        ?.query(
          ...whereClause,
          Q.sortBy('sold', Q.desc),
          Q.take(isMobile ? 9 : 12)
        )
        ?.fetch()
      if (!!prods?.length) {
        setProducts(prods.map((prod: Product) => prod.workaroundSetModel()))
        setShowSyncButton(false)
      } else {
        if (search) setProducts([])
        else setShowSyncButton(true)
      }
    })
    setProductListResolved(true)
  }

  const quantity = (prod: Product) => {
    return factorItemList.find((fi: FactorItem) => fi.id === prod.id)?.qty
  }

  const addItem = (prod: Product) => {
    const founded = factorItemList.find((x: FactorItem) => x.id === prod.id)
    if ((prod.price && !prod.isPriceDynamic) || founded) {
      console.log('eProduct', prod.toItem())
      addFactorItem(prod.toItem())
    } else {
      setProduct(prod)
      setPriceModalOpen(true)
    }
  }

  async function productDbSync() {
    if (!app?.publicId) {
      info('Select an app first', toast)
      return
    }
    if (!!syncing) return
    const syncInterval = setInterval(() => {
      setSyncing((syncing += 0.05))
    }, 500)
    setTimeout(async () => {
      try {
        await GtinDbService.Instance.syncDb(app.publicId)
      } catch (err: any) {
        if (err?.response?.status !== 0)
          failure(remoteErrorExtractor(err), toast)
      }
      setRefreshProductList(!refreshProductList)
      clearInterval(syncInterval)
      setSyncing(0)
    }, 0)
  }

  useEffect(() => {
    fetchProducts()
  }, [refreshProductList, search, app?.publicId])

  return (
    <div id='product-select-card'>
      <IonSearchbar
        value={search}
        placeholder={t('Product or service')}
        onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
      ></IonSearchbar>
      {!app?.publicId && productListResolved && (
        <IonChip color={'danger'}>{t<string>('Select an app first')}</IonChip>
      )}
      {showSyncButton && (
        <div className='ion-text-center'>
          <IonButton onClick={productDbSync}>
            <IonIcon icon={sync} />
          </IonButton>
        </div>
      )}
      {products.map((prod: Product) => (
        <IonChip
          key={prod.id}
          disabled={factorSaving}
          title={productNameOneLine(prod, lang)}
          className={`product-name-chip${
            !search ? ' product-name-chip-max-width' : ''
          }`}
          style={{
            minWidth: !search ? (isMobile ? '30vw' : 'unset') : 'unset',
            maxWidth: !search ? (isMobile ? '30vw' : '130px') : 'unset',
          }}
          onClick={() => addItem(prod)}
        >
          {(quantity(prod) || 0) > 0 && (
            <span>
              <IonBadge color={'dark'}>{quantity(prod)}</IonBadge>
            </span>
          )}
          <ProductName product={prod} wrapText={true} />
        </IonChip>
      ))}
      {!!product && (
        <PriceModal
          isOpen={priceModalOpen}
          product={product}
          onPriceChange={(value: any) => setDynamicPrice(value)}
          onDidDismiss={() => {
            if (dynamicPrice && product) {
              console.log('product', product)
              addFactorItem(product.toItem(dynamicPrice, currency))
              setDynamicPrice(undefined)
              setTimeout(() => {
                setProduct(undefined)
              }, 300)
            }
            setPriceModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    refreshProductList: state.factor.refreshProductList,
    lang: state.user.lang,
    currency: state.user.currency,
    syncing: state.factor.syncing,
  }),
  mapDispatchToProps: {
    setSyncing,
    setRefreshProductList,
  },
  component: memo(ProductSelectCard),
})
