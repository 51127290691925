import { log } from '../components/util/Log'
import { AxiosRequestor } from './AxiosService'
import { CapacitorRequestor } from './CapacitorService'
import { App } from '@capacitor/app'
import { isPlatform } from '@ionic/react'
import { AuthService } from 'ionic-appauth'
import {
  CapacitorBrowser,
  CapacitorSecureStorage,
} from 'ionic-appauth/lib/capacitor'

export class Auth {
  private static authService: AuthService | undefined

  private static buildAuthInstance() {
    const requestor = isPlatform('ios')
      ? new CapacitorRequestor()
      : new AxiosRequestor()
    const authService = new AuthService(
      new CapacitorBrowser(),
      new CapacitorSecureStorage(),
      requestor
    )
    authService.authConfig = {
      client_id: 'taak',
      //client_secret: import.meta.env.VITE_APP_OAUTH_CLIENT_SECRET,
      server_host: 'https://auth.taakcloud.com/realms/taak',
      redirect_url: isPlatform('capacitor')
        ? 'com.taakcloud://callback'
        : window.location.origin + '/authcallback',
      end_session_redirect_url: isPlatform('capacitor')
        ? 'com.taakcloud://endSession'
        : window.location.origin + '/endsession',
      scopes: 'openid offline_access roles taak',
      pkce: true,
    }
    if (isPlatform('capacitor')) {
      log('app listener created')
      App.addListener('appUrlOpen', (data: any) => {
        if (data.url.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(data.url)
        } else {
          authService.endSessionCallback()
        }
      })
    }

    authService.init()
    return authService
  }

  public static get Instance(): AuthService {
    if (!this.authService) {
      this.authService = this.buildAuthInstance()
    }

    return this.authService
  }
}
