import { Model } from '@nozbe/watermelondb'
import { date, text, readonly, field, writer, nochange } from '@nozbe/watermelondb/decorators'
import { fixKafYe } from '../../components/util/Word'
import { FactorItem } from '../mfactor/FactorItem'

export default class Product extends Model {
  static table = 'product'

  @nochange @text('gtin') gtin!: string
  @nochange @text('app_public_id') appPublicId!: string
  @text('name') name!: string
  @text('name_fa') nameFa?: string
  @text('currency') currency?: string
  @field('price') price?: number
  @field('is_price_dynamic') isPriceDynamic?: boolean
  @field('sold') sold?: number
  @text('tax_ir_stuff_id') taxIrStuffId?: string
  @text('tax_ir_stuff_desc') taxIrStuffDesc?: string

  @readonly @date('created_at') createdAt!: number
  @readonly @date('updated_at') updatedAt!: number

  @writer async markAsDeletedForReal() {
    await super.markAsDeleted()
  }

  @writer async soldOneMore() {
    await this.update((product) => {
      product.sold = (product.sold || 0) + 1
      product.workaroundSetRaw()
    })
  }

  workaroundSetRaw() {
    this._setRaw('gtin', this.gtin)
    this._setRaw('name', this.name)
    this._setRaw('name_fa', this.nameFa || null)
    this._setRaw('app_public_id', this.appPublicId)
    this._setRaw('currency', this.currency || '')
    this._setRaw('price', this.price || null)
    this._setRaw('is_price_dynamic', !!this.isPriceDynamic ? true : false)
    this._setRaw('sold', this.sold ? this.sold : 0)
    this._setRaw('tax_ir_stuff_id', this.taxIrStuffId || null)
    this._setRaw('tax_ir_stuff_desc', this.taxIrStuffDesc || null)
    return this
  }

  workaroundSetModel() {
    this.gtin = this._getRaw('gtin') as string
    this.name = this._getRaw('name') as string
    this.nameFa = this._getRaw('name_fa') as string
    this.appPublicId = this._getRaw('app_public_id') as string
    this.currency = this._getRaw('currency') as string
    this.price = this._getRaw('price') as number
    this.isPriceDynamic = this._getRaw('is_price_dynamic') as boolean
    this.sold = this._getRaw('sold') as number
    this.taxIrStuffId = this._getRaw('tax_ir_stuff_id') as string
    this.taxIrStuffDesc = this._getRaw('tax_ir_stuff_desc') as string
    this.createdAt = this._getRaw('created_at') as number
    this.updatedAt = this._getRaw('updated_at') as number
    return this
  }

  toItem = (dynamicPrice?: number, currency?: string): FactorItem => {
    return {
      id: this._raw.id,
      name: this.name,
      nameFa: this.nameFa,
      currency: currency || this.currency || '',
      price: dynamicPrice || this.price || 0,
      qty: 1,
      taxIrStuffId: this.taxIrStuffId,
      taxIrStuffDesc: this.taxIrStuffDesc,
    }
  }

  fixFarsi = (): Product => {
    this.nameFa = fixKafYe(this.nameFa)
    return this
  }
}
