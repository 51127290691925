import { FC, memo } from 'react'
import { IonBadge, IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react'
import { IonLabel, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { log } from '../../components/util/Log'
import currencySign from '../../components/util/currencySign'
import { connect } from '../../data/connect'
import { addFactorItem, removeFactorItem, setFactorItemList } from '../../data/factor/factor.actions'
import { FactorItem } from '../../models/mfactor/FactorItem'
import './FactorItems.scss'

interface OwnProps {}
interface StateProps {
  factorItemList: FactorItem[]
  lang?: string
  factorSaving: boolean
}
interface DispatchProps {
  addFactorItem: typeof addFactorItem
  removeFactorItem: typeof removeFactorItem
  setFactorItemList: typeof setFactorItemList
}
interface FactorItemsProps extends OwnProps, StateProps, DispatchProps {}
const FactorItems: FC<FactorItemsProps> = ({
  factorItemList,
  lang,
  addFactorItem,
  removeFactorItem,
  setFactorItemList,
  factorSaving,
}) => {
  function handleReorder(evt: CustomEvent<ItemReorderEventDetail>) {
    log('Dragged from index', evt.detail.from, 'to', evt.detail.to)
    let movedItem = factorItemList.splice(evt.detail.from, 1)[0]
    factorItemList.splice(evt.detail.to, 0, movedItem)
    setFactorItemList(factorItemList)
    evt.detail.complete()
  }

  return (
    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder} id='factor-items'>
      {factorItemList?.map((factorItem: FactorItem) => (
        <IonItem key={factorItem.id} button={true} disabled={factorSaving}>
          <IonReorder slot='start' className='ion-no-margin'></IonReorder>
          <IonBadge className='ion-margin-end' color={'dark'}>
            {factorItem.qty}
          </IonBadge>
          <IonLabel onClick={() => addFactorItem(factorItem)}>
            {lang === 'fa' && (factorItem.nameFa || factorItem.name)}
            {lang !== 'fa' && (factorItem.name || factorItem.nameFa)}
            <p className='dir-ltr ion-padding-start'>
              {' '}
              {currencySign(factorItem.currency)} {factorItem.price?.toLocaleString(navigator.language)}
            </p>
          </IonLabel>
          <IonButtons slot='end' className='ion-no-margin'>
            <IonButton onClick={() => removeFactorItem(factorItem)} color='danger' disabled={factorSaving}>
              <IonIcon icon={trashBin}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonItem>
      ))}
    </IonReorderGroup>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    factorItemList: state.factor.factorItemList,
    lang: state.user.lang,
    factorSaving: state.factor.factorSaving,
  }),
  mapDispatchToProps: {
    addFactorItem,
    removeFactorItem,
    setFactorItemList,
  },
  component: memo(FactorItems),
})
