import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonButton, IonContent, IonPage, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react'
import { t } from 'i18next'
import { Subscription } from 'rxjs'
import { Auth } from '../../../services/AuthService'

interface EndRedirectPageProps extends RouteComponentProps {}

const EndRedirect: React.FC<EndRedirectPageProps> = (props: EndRedirectPageProps) => {
  const [showRetry, setShowRetry] = useState(false)
  let sub: Subscription

  useIonViewWillEnter(() => {
    Auth.Instance.endSessionCallback()
    sub = Auth.Instance.isAuthenticated$.subscribe((authenticated) => {
      if (!authenticated) {
        props.history.replace('/')
      }
    })
  })

  useIonViewDidLeave(() => {
    sub.unsubscribe()
  })

  useEffect(() => {
    let tout = setTimeout(() => {
      setShowRetry(true)
    }, 10000)
    return () => clearTimeout(tout)
  }, [])

  return (
    <IonPage>
      <IonContent className='ion-padding'>
        <p className='ion-text-center'>
          {t<string>('Signing out...')}
          <br />
          <br />
          {showRetry && (
            <IonButton routerLink='/' routerDirection='none'>
              {t<string>('Back to home')}
            </IonButton>
          )}
        </p>
      </IonContent>
    </IonPage>
  )
}

export default React.memo(EndRedirect)
