import Menu from '../components/Menu'
import UptimeList from '../pages-uptime/UptimeList'
import Account from '../pages/account/Account'
import AdminUsers from '../pages/admin/AdminUsers'
import AdminWebPush from '../pages/admin/AdminWebPush'
import AppBilling from '../pages/app-billing/AppBilling'
import AppCredentials from '../pages/app-credentials/AppCredentials'
import Apps from '../pages/apps/Apps'
import PlanInvoice from '../pages/plan/PlanInvoice'
import PlanInvoiceCallback from '../pages/plan/PlanInvoiceCallback'
import SelectPlanPeriod from '../pages/plan/SelectPlanPeriod'
import Privacy from '../pages/public/Privacy'
import Support from '../pages/public/Support'
import Terms from '../pages/public/Terms'
import About from '../pages/public/about/About'
import Home from '../pages/public/home/Home'
import Login from '../pages/public/login/Login'
import LoginRedirect from '../pages/public/login/LoginRedirect'
import EndRedirect from '../pages/public/login/LogoutRedirect'
import Plans from '../pages/public/plans/Plans'
import ProductDetail from '../pages/public/products/ProductDetail'
import ProductList from '../pages/public/products/ProductList'
import WebPush from '../pages/web-push/WebPush'
import { IonRouterOutlet, IonSplitPane } from '@ionic/react'
import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router'

interface OwnProps extends RouteComponentProps {}
interface RoutesProps extends OwnProps {}
const WebRoutes: FC<RoutesProps> = ({
  history,
  location,
  match,
  staticContext,
}) => {
  return (
    <>
      <IonSplitPane contentId='main'>
        <Menu
          history={history}
          location={location}
          match={match}
          staticContext={staticContext}
        />
        <IonRouterOutlet id='main'>
          {/*
          We use IonRoute here to keep the tabs state intact,
          which makes transitions between tabs and non tab pages smooth
        */}
          <Route path='/' component={Home} exact={true} />
          <Route path='/apps' render={() => <Apps />} exact />
          <Route
            path='/apps/credentials'
            render={() => <AppCredentials />}
            exact
          />
          <Route path='/apps/web-push' render={() => <WebPush />} exact />
          <Route path='/apps/uptime' render={() => <UptimeList />} exact />
          <Route path='/admin/web-push' render={() => <AdminWebPush />} exact />
          <Route path='/admin/users' render={() => <AdminUsers />} exact />
          <Route path='/apps/billing' render={() => <AppBilling />} exact />
          <Route path='/products' render={() => <ProductList />} exact={true} />
          <Route path='/products/:id' component={ProductDetail} exact={true} />
          <Route path='/about' render={() => <About />} exact={true} />
          <Route path='/plans' render={() => <Plans />} exact={true} />
          <Route
            path='/plan/select/:planType'
            component={SelectPlanPeriod}
            exact={true}
          />
          <Route
            path='/plan/invoice/:factorPublicId'
            component={PlanInvoice}
            exact={true}
          />
          <Route
            path='/plan/invoice/callback'
            component={PlanInvoiceCallback}
            exact={true}
          />
          <Route path='/terms' component={Terms} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/support' component={Support} />
          <Route path='/account' component={Account} />
          <Route path='/login' component={Login} />
          <Route path='/authcallback' component={LoginRedirect} exact />
          <Route path='/endsession' component={EndRedirect} exact />
        </IonRouterOutlet>
      </IonSplitPane>
    </>
  )
}

export default React.memo(withRouter(WebRoutes))
