import { FC, memo, useState } from 'react'
import { IonButton, IonCol, IonIcon, IonInput, IonRow } from '@ionic/react'
import { add } from 'ionicons/icons'
import { t } from 'i18next'

interface OwnProps {
  onAdd: (key: string, value: string) => void
}
interface PersonAddFieldProps extends OwnProps {}
const PersonAddField: FC<PersonAddFieldProps> = ({ onAdd }) => {
  const [key, setKey] = useState<string>()
  const [value, setValue] = useState<string>()

  const addTo = () => {
    if (key && value) onAdd(key, value)
    setKey(undefined)
    setValue(undefined)
  }

  return (
    <IonRow>
      <IonCol>
        <IonInput value={key} placeholder={t('Label')} onIonInput={(e: any) => setKey(e.detail.value)} />
      </IonCol>
      <IonCol>
        <IonInput value={value} placeholder={t('Value')} onIonInput={(e: any) => setValue(e.detail.value)} />
      </IonCol>
      <IonCol size='1'>
        <IonButton disabled={!key || !value} onClick={addTo}>
          <IonIcon icon={add} />
        </IonButton>
      </IonCol>
    </IonRow>
  )
}

export default memo(PersonAddField)
