import SignOutButton from '../../components/auth/SignOutButton'
import { utcToLocaleDate } from '../../components/util/Date'
import { log } from '../../components/util/Log'
import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { SupportPlan } from '../../models/SupportPlan'
import { User } from '../../models/User'
import { AppApiService } from '../../services/AppApiService'
import { Auth } from '../../services/AuthService'
import PageContainer from '../PageContainer'
import { IonChip, IonCol, IonGrid, IonInput, IonSpinner } from '@ionic/react'
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  useIonToast,
} from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import TaakSDK from 'taak-sdk'
import { FactorDTO } from 'taak-sdk/dist/mfactor/types'
import { TaakResponse } from 'taak-sdk/dist/taak-response'

interface OwnProps {}
interface StateProps {
  isAuthenticated: boolean
  user?: User
}
interface DispatchProps {}
interface PlanInvoiceCallbackProps
  extends OwnProps,
    StateProps,
    DispatchProps {}
const PlanInvoiceCallback: FC<PlanInvoiceCallbackProps> = ({
  isAuthenticated,
  user,
}) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [publicId] = useState(params.get('publicId'))
  const [factor, setFactor] = useState<FactorDTO>()
  const [supportPlan, setSupportPlan] = useState<SupportPlan>()
  const [trackingCode, setTrackingCode] = useState()
  const [fetching, setFetching] = useState(false)
  const [applying, setApplying] = useState(false)
  const [toast] = useIonToast()
  const taakClient = new TaakSDK({
    apiKey: import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    debug: false,
    local: false,
  })

  const fetchItem = async () => {
    if (fetching) return
    setFetching(true)
    if (publicId) {
      const res: TaakResponse = await taakClient.getFactor(publicId)
      if (res.status === 200) {
        setFactor(res.data)
        setTrackingCode(res.data?.trackingCode)
      } else failure(`${res.status} - ${res.error}`, toast)
    }
    setFetching(false)
  }

  const applySupportPlan = async () => {
    if (applying) return
    log('applying with ', factor?.publicId)
    setApplying(true)
    try {
      const res: AxiosResponse = await AppApiService.Instance.put(
        `/v1/support-plan/public/callback/${factor?.publicId}`,
        {},
        {},
        true
      )
      if (res.status === 200) {
        setSupportPlan(res.data)
        if (res.data.applied && isAuthenticated) loadUserInfo()
      } else {
        failure(`Error ${res.status}`, toast)
      }
    } catch (err: any) {
      failure(JSON.stringify(err), toast)
    }
    setApplying(false)
  }

  const loadUserInfo = async () => {
    await Auth.Instance.loadUserInfo().catch((err: any) =>
      failure(JSON.stringify(err), toast)
    )
  }

  useEffect(() => {
    if (factor?.paid) applySupportPlan()
  }, [factor?.paid])

  useEffect(() => {
    if (publicId && !fetching) fetchItem()
  }, [publicId])

  return (
    <PageContainer
      id='plan-invoice-callback'
      title='Support plan purchase result'
      isPrivate={false}
    >
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonInput
              disabled={true}
              value={trackingCode}
              onIonInput={(e: any) => setTrackingCode(e.detail.value)}
              label={t<string>('Tracking code')}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{t<string>('Status')}</IonCol>
          <IonCol>
            {factor?.paid ? t<string>('Paid') : t<string>('Not paid')}
          </IonCol>
        </IonRow>
        {applying && <TkSpinner />}
        {!applying && (
          <IonList>
            <IonListHeader>{t<string>('Support plan')}</IonListHeader>
            <IonItem>
              <IonLabel>
                {!supportPlan?.type &&
                  !factor?.trackingCode &&
                  !fetching &&
                  !applying &&
                  t<string>('Not found')}
                {supportPlan?.type && t<string>(supportPlan?.type)}
                <p>
                  {(fetching || applying) && <IonSpinner />}
                  {supportPlan?.expiredAt ? (
                    <IonChip color='success'>
                      {t<string>('Applied')},{' '}
                      {t<string>('Valid until', {
                        expiredAt: utcToLocaleDate(supportPlan.expiredAt),
                      })}
                    </IonChip>
                  ) : (
                    <>
                      {!fetching && !applying && (
                        <IonChip color='danger'>
                          {t<string>('Not applied')}
                        </IonChip>
                      )}
                    </>
                  )}
                </p>
              </IonLabel>
            </IonItem>
            {!factor?.paid && factor?.publicId && !fetching && !applying && (
              <IonItem
                button={true}
                routerLink={`/plan/invoice/${factor?.publicId}`}
              >
                <IonLabel>{t<string>('Try again')}</IonLabel>
              </IonItem>
            )}
            {isAuthenticated &&
              supportPlan?.expiredAt &&
              supportPlan?.expiredAt?.substring(0, 10) !== user?.planExpire && (
                <>
                  <br />
                  <br />
                  <IonChip onClick={loadUserInfo}>
                    {t<string>(
                      "If your plan expire date didn't update, please select here or logout and login"
                    )}
                  </IonChip>
                  <SignOutButton />
                </>
              )}
          </IonList>
        )}
      </IonGrid>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    user: state.user.user,
  }),
  component: PlanInvoiceCallback,
})
