import TaakSDK from 'taak-sdk'
import { TaakResponse } from 'taak-sdk/dist/taak-response'
import { WebPushSendCommand } from 'taak-sdk/dist/web-push/types'
import React, { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonChip, IonIcon, IonItem, IonItemOption } from '@ionic/react'
import { IonItemOptions, IonItemSliding, IonLabel, IonList, useIonAlert, useIonToast } from '@ionic/react'
import { paperPlane, trashBin } from 'ionicons/icons'
import { utcToLocale } from '../../components/util/Date'
import { failure, success } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setNet } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { User } from '../../models/User'
import { WebPush } from '../../models/WebPush'

interface OwnProps {
  reload: boolean
}
interface StateProps {
  app?: App
  user: User
}
interface DispatchProps {
  setNet: typeof setNet
}
interface WebPushListProps extends OwnProps, StateProps, DispatchProps {}

const WebPushList: FC<WebPushListProps> = ({ reload, app, setNet, user }) => {
  const [items, setItems] = useState<WebPush[]>()
  const [presentToast] = useIonToast()
  const [presetAlert] = useIonAlert()
  const [testing, setTesting] = useState(false)
  const taakClient = new TaakSDK({
    apiKey: import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    debug: false,
    local: false,
  })

  const fetchItems = async () => {
    setNet(true)
    const res: TaakResponse = await taakClient.getWebPushesByUserId(user.sub)
    if (res.status === 200) {
      setItems(res.data)
    } else {
      failure(`Failure ${res.status}`, presentToast)
    }
    setNet(false)
  }

  const deleteItem = async (itm: WebPush) => {
    setNet(true)
    if (!!itm.publicId) {
      const res: TaakResponse = await taakClient.deleteWebPush(itm.publicId)
      if (res.status === 204) {
        await fetchItems()
      } else {
        failure(`Failure ${res.status}`, presentToast)
      }
    }
    setNet(false)
  }

  const testItem = async (itm: WebPush) => {
    setNet(true)
    setTesting(true)
    const cmd: WebPushSendCommand = {
      payload: 'Hello from Taakestan!',
      publicId: itm.publicId,
    }
    const res: TaakResponse = await taakClient.sendWebPush(cmd)
    if (res.status === 201) {
      success('Success sending web push', presentToast)
    } else {
      presentToast({
        message: `Status ${res.status}`,
        color: res.status !== 410 ? 'tertiary' : 'danger',
        duration: 3000,
      })
      if (res.status === 401)
        setTimeout(async () => {
          await fetchItems()
        }, 3000)
    }
    setTesting(false)
    setNet(false)
  }

  useEffect(() => {
    if (!!app?.publicId) fetchItems()
  }, [reload, app?.publicId]) // eslint-disable-line

  return (
    <IonList>
      <h3 style={{ marginTop: '40px' }}>Web Push</h3>
      {items?.map((itm: WebPush) => (
        <IonItemSliding key={itm.publicId}>
          <IonItem>
            <IonLabel className='ion-text-wrap'>
              {itm.deviceId?.split('::')?.map((itm: string) => (
                <IonChip key={itm}>{itm}</IonChip>
              ))}
              <p>{utcToLocale(itm?.createdAt)}</p>
            </IonLabel>
            <IonButtons slot='end'>
              <IonButton fill='clear' onClick={() => testItem(itm)} disabled={testing}>
                <IonIcon icon={paperPlane} slot='icon-only' />
              </IonButton>
            </IonButtons>
          </IonItem>
          <IonItemOptions>
            <IonItemOption
              color={'danger'}
              onClick={() =>
                presetAlert({
                  header: 'Delete Web Push',
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                    },
                    {
                      text: 'Delete',
                      role: 'destructive',
                      handler: () => {
                        deleteItem(itm)
                      },
                    },
                  ],
                })
              }>
              <IonIcon icon={trashBin} slot='bottom' />
              Delete
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
      {items?.length === 0 && (
        <IonItem>
          <IonLabel>No record to display</IonLabel>
        </IonItem>
      )}
    </IonList>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    user: state.user.user,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: React.memo(WebPushList),
})
