import React, { FC, useEffect } from 'react'
import { IonItem, IonToggle, useIonToast } from '@ionic/react'
import { t } from 'i18next'
import { log } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setWebPush } from '../../data/sessions/sessions.actions'

interface OwnProps {}
interface StateProps {
  webPush?: boolean
}
interface DispatchProps {
  setWebPush: typeof setWebPush
}
interface WebPushToggleProps extends OwnProps, StateProps, DispatchProps {}
const WebPushToggle: FC<WebPushToggleProps> = ({ webPush, setWebPush }) => {
  const [present] = useIonToast()

  const askForPermission = () => {
    return new Promise(function (resolve, reject) {
      const permissionResult = Promise.resolve(Notification.requestPermission())
      if (permissionResult) {
        permissionResult.then(resolve, reject)
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        if (permissionResult === 'denied') {
          failure(
            `Permission ${permissionResult}, you need to unblock ${window.location.origin} in your browser to receive notifications.`,
            present
          )
        }
        setWebPush(false)
      }
    })
  }

  const checkIfPermissionGiven = async () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: 'notifications' })
        .then((res) => {
          log('push notification permission', res.state)
          if (res?.state !== 'granted') {
            askForPermission()
          }
        })
        .catch((err) => {
          log('push notification permission check failure', err)
          askForPermission()
        })
    }
  }

  useEffect(() => {
    //log('webPush', webPush)
    if (webPush) checkIfPermissionGiven()
  }, [webPush]) // eslint-disable-line

  return (
    <IonItem lines='none'>
      <IonToggle checked={webPush} onIonChange={(e) => setWebPush(e.detail.checked)}>
        {t<string>('Enable push notifications')}
      </IonToggle>
    </IonItem>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    webPush: state.data.webPush,
  }),
  mapDispatchToProps: {
    setWebPush,
  },
  component: React.memo(WebPushToggle),
})
