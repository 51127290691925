import React from 'react'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { t } from 'i18next'
import ProductItem from '../../../components/ProductItem'
import { connect } from '../../../data/connect'
import * as selectors from '../../../data/selectors'
import { Product } from '../../../models/Product'
import PageContainer from '../../PageContainer'
import './ProductList.scss'

interface OwnProps {}
interface StateProps {
  products: Product[]
}
interface DispatchProps {}
interface ProductListProps extends OwnProps, StateProps, DispatchProps {}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  return (
    <PageContainer id='product-list' title={t<string>('Products')} isPrivate={false}>
      <IonGrid fixed>
        <IonRow>
          {products?.map((product) => (
            <IonCol size='12' size-md='6' key={product.id}>
              <ProductItem key={product.id} product={product} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    products: selectors.getProducts(state),
  }),
  component: React.memo(ProductList),
})
