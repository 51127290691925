import { createSelector } from 'reselect'
import { Product } from '../models/Product'
import { AppState } from './state'

export const getProducts = (state: AppState) => state.data.products

const getIdParam = (_state: AppState, props: any) => {
  return props.match.params['id']
}

export const getProduct = createSelector(getProducts, getIdParam, (products, id) =>
  products?.find((x: Product) => x.id === id)
)
