import { utils } from '@taak/react-modern-calendar-datepicker'
import { isAfter, isBefore, parseISO } from 'date-fns'
import TaakSDK from 'taak-sdk'
import { FactorCreateCommand, FactorDTO } from 'taak-sdk/dist/mfactor/types'
import { TaakResponse } from 'taak-sdk/dist/taak-response'
import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import { IonList, IonListHeader, IonRow, IonSelect, IonSelectOption, IonSpinner, useIonToast } from '@ionic/react'
import { IonButton, IonCol, IonGrid, IonIcon } from '@ionic/react'
import { checkmark, close, refresh } from 'ionicons/icons'
import { t } from 'i18next'
import { AxiosResponse } from 'axios'
import { utcToLocaleDate } from '../../components/util/Date'
import { error } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { PlanPeriod, planPeriodMonths } from '../../models/PlanPeriod'
import { SupportPlan } from '../../models/SupportPlan'
import { User } from '../../models/User'
import { AppApiService } from '../../services/AppApiService'
import PageContainer from '../PageContainer'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  user?: User
  isAuthenticated: boolean
}
interface DispatchProps {}
interface SelectPlanPeriodProps extends OwnProps, StateProps, DispatchProps {}
const SelectPlanPeriod: FC<SelectPlanPeriodProps> = ({ user, history, isAuthenticated }) => {
  const taakClient = new TaakSDK({
    apiKey: import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    debug: false,
    local: false,
  })
  const { planType } = useParams<any>()
  const [planPeriod, setPlanPeriod] = useState<PlanPeriod>('ONE_MONTH')
  const [toast] = useIonToast()
  const [inprogress, setInprogress] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [items, setItems] = useState<SupportPlan[]>()

  const createSupportPlanEntity = async (factor: FactorDTO) => {
    setInprogress(true)
    const res: TaakResponse = await AppApiService.Instance.post(`/v1/support-plan/create`, {
      type: planType,
      period: planPeriod,
      factorTrackingCode: factor.trackingCode,
      factorPublicId: factor.publicId,
    })
    if (res.error) {
      failure(res.error, toast)
    } else {
      history.push(`/plan/invoice/${factor.publicId}`)
    }
    setInprogress(false)
  }

  const createFactor = async () => {
    setInprogress(true)
    const cmd: FactorCreateCommand = {
      appPublicId: import.meta.env.VITE_APP_WEB_PUSH_APP_PUBLIC_ID,
      currency: 'IRR',
      total: planPeriodMonths(planPeriod) * 1000000,
      factorDate: new Date().toISOString(),
      items: [
        {
          name: 'Startup support plan',
          nameFa: 'طرح پشتیبانی نوپا',
          price: 1000000,
          quantity: planPeriodMonths(planPeriod),
        },
      ],
      payer:
        user?.given_name || user?.family_name ? `${user?.given_name} ${user?.family_name}` : user?.email || 'unknown',
    }
    const res: TaakResponse = await taakClient.createFactor(cmd)
    if (res.status === 200) {
      createSupportPlanEntity(res.data)
    } else {
      failure(res.error, toast)
      setInprogress(false)
    }
  }

  const fetchItems = async () => {
    setFetching(true)
    try {
      const res: AxiosResponse = await AppApiService.Instance.get(`/v1/support-plan/list`)
      if (res.status === 200) {
        setItems(res.data)
      }
    } catch (err) {
      error('Fetch error', err)
    }
    setFetching(false)
  }

  const isNotExpired = (expiredAt: string) => {
    const today = utils('en').getToday()
    return expiredAt ? isAfter(parseISO(expiredAt), new Date(today.year, today.month - 1, today.day)) : true
  }

  useEffect(() => {
    setTimeout(() => {
      fetchItems()
    }, 300)
  }, [])

  return (
    <PageContainer id='select-plan-period' title='Select support plan period' isPrivate={true}>
      <IonGrid>
        <IonRow>
          <IonCol>{t<string>('Support plan')}</IonCol>
          <IonCol>{t<string>(planType)}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{t<string>('Period')}</IonCol>
          <IonCol>
            <IonSelect
              disabled={inprogress}
              value={planPeriod}
              onIonChange={(e: any) => setPlanPeriod(e.detail.value)}
              okText={t<string>('Confirm')}
              cancelText={t<string>('Cancel')}>
              <IonSelectOption value={'ONE_MONTH'}>{t<string>('One month')}</IonSelectOption>
              <IonSelectOption value={'THREE_MONTHS'}>{t<string>('Three months')}</IonSelectOption>
              <IonSelectOption value={'SIX_MONTHS'}>{t<string>('Six months')}</IonSelectOption>
              <IonSelectOption value={'ONE_YEAR'}>{t<string>('One year')}</IonSelectOption>
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>&nbsp;</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonButton style={{ minWidth: '300px' }} disabled={inprogress} onClick={createFactor}>
              {t<string>('Continue')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <br />
      <IonList>
        <IonListHeader>
          {t<string>('Previous support plans')}
          {!fetching && (
            <IonButton fill='clear' onClick={fetchItems}>
              <IonIcon icon={refresh} slot='icon-only' />
            </IonButton>
          )}
          {fetching && <IonSpinner className='ion-margin-start' />}
        </IonListHeader>
        <IonRow className='header'>
          <IonCol>{t<string>('Create date')}</IonCol>
          <IonCol>{t<string>('Tracking code')}</IonCol>
          <IonCol>{t<string>('Status')}</IonCol>
          <IonCol>{t<string>('Expire date')}</IonCol>
          <IonCol></IonCol>
        </IonRow>
        {items?.length &&
          items.map((itm: SupportPlan) => (
            <IonRow key={itm.factorPublicId}>
              <IonCol>{utcToLocaleDate(itm.createdAt)}</IonCol>
              <IonCol>{itm.factorTrackingCode}</IonCol>
              <IonCol>
                <IonIcon
                  icon={itm.applied ? checkmark : close}
                  color={itm.applied ? 'success' : 'danger'}
                  size='large'
                />
              </IonCol>
              <IonCol>{utcToLocaleDate(itm.expiredAt)}</IonCol>
              <IonCol>
                {!itm.applied && (
                  <IonButton routerLink={`/plan/invoice/${itm.factorPublicId}`}>{t<string>('Continue')}</IonButton>
                )}
                {itm.applied &&
                  isAuthenticated &&
                  isNotExpired(itm.expiredAt) &&
                  itm?.expiredAt?.substring(0, 10) !== user?.planExpire && (
                    <IonButton routerLink={`/plan/invoice/callback?publicId=${itm.factorPublicId}`}>
                      {t<string>('Double check')}
                    </IonButton>
                  )}
              </IonCol>
            </IonRow>
          ))}
      </IonList>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
    isAuthenticated: state.user.isAuthenticated,
  }),
  component: SelectPlanPeriod,
})
