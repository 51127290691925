import { log } from '../../../components/util/Log'
import { connect } from '../../../data/connect'
import {
  setDoubleRendering,
  setLastRoute,
} from '../../../data/user/user.actions'
import { Auth } from '../../../services/AuthService'
import {
  IonButton,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from '@ionic/react'
import { t } from 'i18next'
import { AuthActions } from 'ionic-appauth'
import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Subscription } from 'rxjs'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  lastRoute: string
  doubleRendering: boolean
}
interface DispatchProps {
  setDoubleRendering: typeof setDoubleRendering
  setLastRoute: typeof setLastRoute
}
interface LoginRedirectProps extends OwnProps, StateProps, DispatchProps {}

const LoginRedirect: FC<LoginRedirectProps> = ({
  lastRoute,
  doubleRendering,
  setDoubleRendering,
  location,
  history,
}) => {
  let sub: Subscription
  const [showRetry, setShowRetry] = useState(false)
  const [signInInprogress, setSignInInprogress] = useState(false)

  useIonViewDidEnter(() => {
    setTimeout(() => setDoubleRendering(true), 0)
    if (!doubleRendering) {
      let url = window.location.origin + location.pathname + location.search
      log('', new Date().getTime(), 'LoginRedirectViewDidEnter', url)
      Auth.Instance.authorizationCallback(url)
      sub = Auth.Instance.events$.subscribe((action) => {
        log('LoginRedirect#AuthAction', action.action)
        if (action.action === AuthActions.LoadTokenFromStorageFailed) {
          // setShowRetry(true)
        }
        if (
          action.action === AuthActions.SignInSuccess ||
          action.action === AuthActions.RefreshSuccess
        ) {
          log(
            'SignIn Success Redirect to ',
            localStorage.getItem('CapacitorStorage.lastRoute'),
            lastRoute
          )
          history.replace(
            localStorage.getItem('CapacitorStorage.lastRoute') || lastRoute
          ) // Patched with using localStorage directly
          setLastRoute(undefined)
          setTimeout(() => {
            Auth.Instance.loadUserInfo()
              .then()
              .catch((err) => log('LoginRedirect::loadUserInfo', err))
          }, 0)
        }
        if (action.action === AuthActions.SignInFailed) {
          log('Redirect to home')
          //setInterval(() => props.history.replace('/'), 2500)
        }
      })
    }
  })

  useIonViewDidLeave(() => {
    sub.unsubscribe()
    setShowRetry(false)
  })

  const handleSignIn = async (e: any) => {
    setSignInInprogress(true)
    e.preventDefault()
    try {
      await Auth.Instance.signIn()
        .then()
        .catch(() => {})
    } catch (ignore) {}
    setTimeout(() => {
      setSignInInprogress(false)
    }, 5000)
  }

  useEffect(() => {
    let tout = setTimeout(() => {
      setShowRetry(true)
    }, 10000)
    return () => clearTimeout(tout)
  }, [])

  return (
    <IonPage id='login-redirect'>
      <IonContent className='ion-padding'>
        <p className='ion-text-center'>
          {t<string>('Signing in...')}
          <br />
          <br />
          {showRetry && (
            <IonButton disabled={signInInprogress} onClick={handleSignIn}>
              {t<string>('Retry')}
            </IonButton>
          )}
        </p>
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lastRoute: state.user.lastRoute,
    doubleRendering: state.user.doubleRendering,
  }),
  mapDispatchToProps: {
    setDoubleRendering,
    setLastRoute,
  },
  component: React.memo(withRouter(LoginRedirect)),
})
