import { utils } from '@taak/react-modern-calendar-datepicker'
import { utcToZonedTime } from 'date-fns-tz'
import jalaali from 'jalaali-js'
import { defaultTimezone } from './Date'

export interface Day {
  year: number
  month: number
  day: number
}

function toGregorian(date: Day) {
  const gDate = jalaali.toGregorian(date.year, date.month, date.day)
  return `${gDate.gy}-${('00' + gDate.gm).slice(-2)}-${('00' + gDate.gd).slice(-2)}`
}

function toGregorianDay(isoTime: string): Day {
  const year = isoTime.substring(0, 4)
  const month = isoTime.substring(5, 7)
  const day = isoTime.substring(8, 10)
  return { year: +year, month: +month, day: +day }
}

function toJalali(date: Day) {
  const jDate = jalaali.toJalaali(date.year, date.month, date.day)
  return { year: jDate.jy, month: jDate.jm, day: jDate.jd }
}

const dateTimeDisplay = (isoTime: string, lang?: string) => {
  const zonedTime = utcToZonedTime(isoTime, defaultTimezone())
  if (lang === 'fa') {
    const faDate = toJalali(toGregorianDay(isoTime))
    return `${zonedTime?.getHours()}:${zonedTime?.getMinutes()} ,${faDate.year} ,${utils('fa').getMonthName(
      faDate.month
    )} ${faDate.day}`
  } else {
    const enDate = toGregorianDay(isoTime)
    return `${enDate.day} ${utils('en').getMonthName(enDate.month)}, ${
      enDate.year
    } at ${zonedTime?.getHours()}:${zonedTime?.getMinutes()}`
  }
}

const dateDisplay = (isoTime: string, lang?: string) => {
  if (lang === 'fa') {
    const faDate = toJalali(toGregorianDay(isoTime))
    return `${faDate.year} ,${utils('fa').getMonthName(faDate.month)} ${faDate.day}`
  } else {
    const enDate = toGregorianDay(isoTime)
    return `${enDate.day} ${utils('en').getMonthName(enDate.month)}, ${enDate.year}`
  }
}

export { toGregorian, toJalali, toGregorianDay, dateTimeDisplay, dateDisplay }
