import React from 'react'
import { IonCard, IonCardHeader, IonItem, IonLabel, IonAvatar, IonCardContent, IonList, IonIcon } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { t } from 'i18next'
import { Product } from '../models/Product'

interface ProductItemProps {
  product: Product
}

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  return (
    <>
      <IonCard className='product-card'>
        <IonCardHeader>
          <IonItem
            disabled={product.disabled}
            button
            detail={false}
            lines='none'
            className='product-item'
            routerLink={`/products/${product.id}`}>
            <IonAvatar slot='start'>
              <img src={product.pic} alt='Product pic' />
            </IonAvatar>
            <IonLabel>
              <h2>{product.name}</h2>
              <p>{t<string>(product.title)}</p>
            </IonLabel>
          </IonItem>
        </IonCardHeader>

        <IonCardContent>
          <IonList lines='none'>
            <IonItem disabled={product.disabled} detail={false} routerLink={`/products/${product.id}`}>
              <IonLabel>
                {!product.disabled && <h3>{t<string>(product.about)}</h3>}
                {product.disabled && <h3>{t<string>('Coming soon')}</h3>}
              </IonLabel>
              <IonIcon icon={checkmark} slot='end' color='success' />
            </IonItem>
            {product?.features?.map((f: string) => (
              <IonItem key={f} disabled={product.disabled} detail={false} routerLink={`/products/${product.id}`}>
                <IonLabel>{t<string>(f)}</IonLabel>
                <IonIcon icon={checkmark} slot='end' color='success' />
              </IonItem>
            ))}
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  )
}

export default React.memo(ProductItem)
