const log = (msg?: string, ...optionalParams: any[]) => {
  if (import.meta.env.VITE_APP_LOG_ENABLED) {
    console.log(msg, optionalParams)
  }
}

const error = (msg?: string, ...optionalParams: any[]) => {
  if (import.meta.env.VITE_APP_LOG_ERROR_ENABLED) {
    console.error(msg, optionalParams)
  }
}

export { log, error }
