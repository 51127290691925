import React, { FC } from 'react'
import { IonButton, IonText } from '@ionic/react'
import { connect } from '../data/connect'
import { App } from '../models/App'
import PageContainer from './PageContainer'

interface OwnProps {
  id: string
  title?: string
  actionButtonsStart?: any
  actionButtonsEnd?: any
  children?: any
}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface AppContainerProps extends OwnProps, StateProps, DispatchProps {}
const AppContainer: FC<AppContainerProps> = ({ id, app, children, title, actionButtonsStart, actionButtonsEnd }) => {
  return (
    <PageContainer
      id={id}
      isPrivate={true}
      title={title}
      actionButtonsStart={actionButtonsStart}
      actionButtonsEnd={actionButtonsEnd}>
      {!!app && <>{children}</>}
      {!app && (
        <>
          <IonText>Please select your </IonText>
          <IonButton className='ion-margin-bottom' routerLink='/apps'>
            App
          </IonButton>
          <IonText> first</IonText>
        </>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: React.memo(AppContainer),
})
