import Product from '../models/gtin/Product'
import { ProductNames } from '../models/gtin/ProductNames'

const productNameOneLine = (product: Product, lang?: string) => {
  if (lang === 'fa') return product.nameFa || product.name
  else return product.name || product.nameFa
}

const productNameWith = (productNames?: ProductNames, lang?: string): string => {
  if (productNames) {
    if (lang === 'fa') return productNames.nameFa || productNames.name
    else return productNames.name || productNames.nameFa || ''
  } else {
    return ''
  }
}

export { productNameOneLine, productNameWith }
