import React, { FC, lazy, useEffect, useRef, useState } from 'react'
import { IonModal, useIonViewDidEnter } from '@ionic/react'
import './BarcodeScannerModal.scss'

const BarcodeScanner = lazy(() => import('../components/util/BarcodeScanner'))

interface BarcodeScannerModalProps {
  onDetected?: Function
}
const BarcodeScannerModal: FC<BarcodeScannerModalProps> = ({ onDetected }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const scannerRef = useRef<HTMLDivElement>(null)
  const [showScanner, setShowScanner] = useState(false)

  useIonViewDidEnter(() => {
    setShowScanner(true)
  })

  useEffect(() => {
    setShowScanner(true)
  }, [])

  return (
    <IonModal
      id='barcode-scanner-modal'
      ref={modal}
      trigger='open-barcode-scanner-modal'
      showBackdrop={false}
      onDidDismiss={() => setShowScanner(false)}
      onDidPresent={() => setShowScanner(true)}
      backdropDismiss={true}>
      <div
        ref={scannerRef}
        className='text-center viewport dir-ltr'
        id='interactive'
        style={{ width: '640', height: '480' }}>
        <canvas
          className='drawingBuffer'
          style={{ position: 'absolute', top: '0px', border: '3px solid green' }}
          width='640'
          height='480'
        />
        {showScanner && (
          <BarcodeScanner
            scannerRef={scannerRef}
            onDetected={(result: any) => {
              if (onDetected) onDetected(result)
              modal.current?.dismiss(result, 'found')
            }}
            constraints={{ width: '640', height: '480' }}
            locate={true}
          />
        )}
      </div>
    </IonModal>
  )
}

export default React.memo(BarcodeScannerModal)
