import ActionModal from '../components/ActionModal'
import { App } from '../models/App'
import { Uptime } from '../models/uptime/Uptime'
import UptimeEdit from './UptimeEdit'
import { IonButton, IonIcon } from '@ionic/react'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import React, { FC, useState } from 'react'

interface OwnProps {
  onSuccess?: (up: Uptime) => void
}
interface AppEditButtonProps extends OwnProps {}
const AppEditButton: FC<AppEditButtonProps> = ({ onSuccess }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <IonButton
        onClick={() => setShowModal(true)}
        className='ion-margin-bottom'
      >
        <IonIcon icon={add} slot='start'></IonIcon>
        {t<string>('New Uptime')}
      </IonButton>
      <ActionModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        id='uptime-new'
      >
        <UptimeEdit
          onSuccess={async (up: Uptime) => {
            setShowModal(false)
            if (!!onSuccess) onSuccess(up)
          }}
        />
      </ActionModal>
    </>
  )
}

export default React.memo(AppEditButton)
