import { FC, memo, useEffect, useRef, useState } from 'react'
import { IonButton, IonCol, IonModal, IonRow } from '@ionic/react'
import { t } from 'i18next'
import CurrencyIonInput from '../components/CurrencyIonInput'
import { connect } from '../data/connect'
import { setCurrency } from '../data/user/user.actions'
import Product from '../models/gtin/Product'
import './PriceModal.scss'
import ProductName from './ProductName'

interface OwnProps {
  isOpen: boolean
  product: Product
  onPriceChange: Function
  onDidDismiss?: (event: any) => void
}
interface StateProps {
  currency?: string
}
interface DispatchProps {
  setCurrency: typeof setCurrency
}
interface PriceModalProps extends OwnProps, StateProps, DispatchProps {}
const PriceModal: FC<PriceModalProps> = ({ product, currency, onPriceChange, isOpen, onDidDismiss }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [price, setPrice] = useState(product.price)

  useEffect(() => {
    onPriceChange(price || product.price)
  }, [price, product.price])

  return (
    <IonModal
      ref={modal}
      id='price-modal'
      isOpen={isOpen}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      onDidDismiss={onDidDismiss}>
      <div className='price-block'>
        <IonRow>
          <IonCol>
            <ProductName product={product} />
          </IonCol>
        </IonRow>
        <CurrencyIonInput
          defaultValue={product.price + ''}
          defaultCurrency={currency}
          onValueChange={(value: any) => setPrice(value)}
          onCurrencyChange={(value: any) => setCurrency(value)}
          label={t<string>('Price')}
        />
        <IonRow>
          <IonCol>
            <IonButton expand='full' style={{ width: '300px' }} onClick={() => modal.current?.dismiss()}>
              {t<string>('Ok')}
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </IonModal>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    currency: state.user.currency,
  }),
  mapDispatchToProps: {
    setCurrency,
  },
  component: memo(PriceModal),
})
