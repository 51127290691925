import { formatISO } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { IonCol, IonRow } from '@ionic/react'
import TkDateTime from '../../components/util/TkDateTime'
import { connect } from '../../data/connect'
import { setFactorDate, setFactorPayer } from '../../data/factor/factor.actions'
import { FactorItem } from '../../models/mfactor/FactorItem'
import FactorItems from '../factor/FactorItems'
import FactorTotal from '../factor/FactorTotal'
import { PersonOption } from '../person/PersonOption'
import PersonSelect from '../person/PersonSelect'

interface OwnProps {}
interface StateProps {
  factorItemList: FactorItem[]
  factorDate?: string
  factorPayer?: PersonOption
  factorSaving: boolean
}
interface DispatchProps {
  setFactorDate: typeof setFactorDate
  setFactorPayer: typeof setFactorPayer
}
interface DeskFormProps extends OwnProps, StateProps, DispatchProps {}
const DeskForm: FC<DeskFormProps> = ({
  factorItemList,
  factorDate,
  setFactorDate,
  factorPayer,
  setFactorPayer,
  factorSaving,
}) => {
  const [price, setPrice] = useState<number>()
  const [currencies, setCurrencies] = useState<Set<string>>(new Set())
  const [showWithDelay, setShowWithDelay] = useState(false)
  const [defaultDate, setDefaultDate] = useState(formatISO(new Date()))

  const calcPrice = () => {
    setPrice(factorItemList.reduce((a: any, c: any) => a + c.qty * c.price, 0))
  }

  const hasMultiCurrency = () => {
    const currencySet = new Set<string>()
    factorItemList.map((fi) => {
      if (fi.currency) currencySet.add(fi.currency)
    })
    setCurrencies(currencySet)
    return currencySet.size > 1
  }

  useEffect(() => {
    if (factorSaving) setDefaultDate(formatISO(new Date()))
    setTimeout(() => {
      setShowWithDelay(true)
    }, 300)
  }, [factorSaving])

  useEffect(() => {
    calcPrice()
    hasMultiCurrency()
  }, [factorItemList])

  return (
    <div>
      <IonRow>
        <IonCol>
          <FactorTotal factorItems={factorItemList} />
        </IonCol>
      </IonRow>
      <FactorItems />
      <div style={{ minHeight: '85px' }}>
        {showWithDelay && !factorSaving && (
          <>
            <PersonSelect defaultValue={factorPayer} onChange={(value?: PersonOption) => setFactorPayer(value)} />
            <TkDateTime
              id='desk-factor-date'
              defaultDate={defaultDate}
              onChange={(value?: string) => setFactorDate(value)}
              calendarPopperPosition='bottom'
            />
          </>
        )}
      </div>
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    factorItemList: state.factor.factorItemList,
    factorDate: state.factor.factorDate,
    factorPayer: state.factor.factorPayer,
    factorSaving: state.factor.factorSaving,
  }),
  mapDispatchToProps: {
    setFactorDate,
    setFactorPayer,
  },
  component: React.memo(DeskForm),
})
