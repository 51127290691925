import { t } from 'i18next'
import Modal from '../../components/Modal'
import PersonAddButton from './PersonAddButton'
import PersonList from './PersonList'

const ProductListModal = () => {
  return (
    <Modal
      id='person-list'
      onDidDismiss={() => {}}
      trigger='open-person-list'
      title={t<string>('Person list')}
      actionButtonsEnd={<PersonAddButton onDismiss={() => {}} />}>
      <PersonList />
    </Modal>
  )
}

export default ProductListModal
