import { combineReducers } from './combineReducers'
import { factorReducer } from './factor/factor.reducer'
import { sessionsReducer } from './sessions/sessions.reducer'
import { userReducer } from './user/user.reducer'

export const initialState: AppState = {
  data: {
    schedule: { groups: [] } as any,
    mapCenterId: 0,
    loading: false,
    menuEnabled: true,
    webPush: false,
  },
  user: {
    hasSeenTutorial: true,
    darkMode: false,
    isAuthenticated: false,
    loading: false,
    net: false,
    lastRoute: '/',
    hand: 'right',
  },
  factor: {
    factorItemList: [],
    refreshProductList: false,
    refreshPersonList: false,
    syncing: 0,
    scanBeep: true,
    showScanner: true,
    factorSaving: false,
  },
}

export const reducers = combineReducers({
  data: sessionsReducer,
  user: userReducer,
  factor: factorReducer,
})

export type AppState = ReturnType<typeof reducers>
