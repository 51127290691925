import { TokenResponse } from '@openid/appauth'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Auth } from './AuthService'

export class GtinService {
  private static gtinService: GtinService | undefined
  private axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }

  public async get(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    const validToken: TokenResponse = await Auth.Instance.getValidToken()
    defaultConfig.headers = {
      ...defaultConfig.headers,
      Authorization: `Bearer ${validToken.accessToken}`,
    }
    return this.axiosInstance.get(path, defaultConfig)
  }

  public async post(path: string, data: any, config?: AxiosRequestConfig, secure = true): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    if (secure) {
      const validToken: TokenResponse = await Auth.Instance.getValidToken()
      defaultConfig.headers = {
        ...defaultConfig.headers,
        Authorization: `Bearer ${validToken.accessToken}`,
      }
    }
    return this.axiosInstance.post(path, data, defaultConfig)
  }

  public async put(path: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    const validToken: TokenResponse = await Auth.Instance.getValidToken()
    defaultConfig.headers = {
      ...defaultConfig.headers,
      Authorization: `Bearer ${validToken.accessToken}`,
    }
    return this.axiosInstance.put(path, data, defaultConfig)
  }

  public async delete(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const defaultConfig = !!config ? config : {}
    const validToken: TokenResponse = await Auth.Instance.getValidToken()
    defaultConfig.headers = {
      ...defaultConfig.headers,
      Authorization: `Bearer ${validToken.accessToken}`,
    }
    return this.axiosInstance.delete(path, defaultConfig)
  }

  private static buildApiInstance() {
    const axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_GTIN_BASE_URI,
    })
    return new GtinService(axiosInstance)
  }

  public static get Instance(): GtinService {
    if (!this.gtinService) {
      this.gtinService = this.buildApiInstance()
    }

    return this.gtinService
  }
}
