import { FC, memo, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react'
import { addCircle, save, trashBin } from 'ionicons/icons'
import { t } from 'i18next'
import { TResponse } from '../../components/util/TResponse'
import { failure, success } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { AppApiService } from '../../services/AppApiService'
import { UserRepresentation } from './UserRepresentation'

interface OwnProps {
  userRepresentation: UserRepresentation
  onDismiss: Function
}
interface StateProps {
  hand: 'right' | 'left'
}
interface AdminUserEditModalProps extends OwnProps, StateProps {}
const AdminUserEditModal: FC<AdminUserEditModalProps> = ({ userRepresentation, hand, onDismiss }) => {
  const [updating, setUpdating] = useState(false)
  const [attributes, setAttributes] = useState(userRepresentation.attributes)
  const [attributeKey, setAttributeKey] = useState<any>()
  const [attributeValue, setAttributeValue] = useState()
  const [toast] = useIonToast()

  const updateCustomAttributes = async () => {
    setUpdating(true)
    try {
      const res: TResponse = await AppApiService.Instance.post(`/v1/admin/users/updateCustomAttributes`, {
        id: userRepresentation.id,
        attributes,
      })
      if (res.status === 200) {
        success('Attributes updated', toast)
      }
      onDismiss({ ...userRepresentation, attributes }, 'updated')
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setUpdating(false)
  }

  const removeAttribute = (key: string) => {
    const attrs = { ...attributes }
    delete attrs[key]
    setAttributes(attrs)
  }

  const addAttribute = () => {
    setAttributes({ ...attributes, [attributeKey]: [attributeValue] })
    setAttributeKey(undefined)
    setAttributeValue(undefined)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{t<string>('Edit user')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonInput value={userRepresentation.username} disabled />
        <IonInput value={userRepresentation.firstName} disabled />
        <IonInput value={userRepresentation.lastName} disabled />
        <IonLabel>{t<string>('Attributes')}:</IonLabel>
        <>
          {!!attributes &&
            Object.keys(attributes)?.map((k: string) => (
              <IonRow key={k}>
                <IonCol className='ion-margin-start'>
                  <IonInput value={k} />
                </IonCol>
                <IonCol>
                  <IonInput value={attributes?.[k]} />
                </IonCol>
                <IonCol>
                  <IonButton color={'danger'} fill='clear' onClick={() => removeAttribute(k)}>
                    <IonIcon icon={trashBin} slot='icon-only' />
                  </IonButton>
                </IonCol>
              </IonRow>
            ))}
        </>
        <IonRow>
          <IonCol className='ion-margin-start'>
            <IonInput
              value={attributeKey}
              onIonInput={(e: any) => setAttributeKey(e.detail.value)}
              placeholder={t<string>('Key')}
            />
          </IonCol>
          <IonCol>
            <IonInput
              value={attributeValue}
              onIonInput={(e: any) => setAttributeValue(e.detail.value)}
              placeholder={t<string>('Value')}
            />
          </IonCol>
          <IonCol>
            <IonButton fill='clear' onClick={() => addAttribute()} disabled={!attributeKey || !attributeValue}>
              <IonIcon icon={addCircle} slot='icon-only' />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => updateCustomAttributes()} disabled={updating} color='primary' fill='outline'>
              {!updating && <IonIcon icon={save} slot='start' />}
              {updating && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
  }),
  component: memo(AdminUserEditModal),
})
