import React from 'react'
import { IonRouterLink } from '@ionic/react'
import { t } from 'i18next'
import { connect } from '../../../data/connect'
import PageContainer from '../../PageContainer'
import './Home.scss'

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

type HomePageProps = OwnProps & StateProps & DispatchProps

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <PageContainer id='home-page' title='Taak' isPrivate={false}>
      <h1>{t<string>('Grow your app organically!')}</h1>
      <p>
        {t<string>('HomeP1')} {t<string>('HomeP1_1')} {t<string>('HomeP1_2')}
      </p>
      <ul>
        <li>
          {t<string>('Send')}{' '}
          <IonRouterLink href='/products/web-push'>{t<string>('Web Push Notifications')}</IonRouterLink>{' '}
          {t<string>('conveniently')}.
        </li>
        <li>
          {t<string>('Issue')} <IonRouterLink href='/products/m-factor'>{t<string>('Micro Factor')}</IonRouterLink>{' '}
          {t<string>('on scale cheap')}.
        </li>
      </ul>
      <p>
        {t<string>('HomeP2')} <IonRouterLink href='/support'>{t<string>('HomeP2_1')}</IonRouterLink> {t<string>('or')}{' '}
        <IonRouterLink target='_blank' href='https://github.com/taakcloud/taakcloud.github.io/issues/new'>
          {t<string>('HomeP2_2')}
        </IonRouterLink>{' '}
        {t<string>('HomeP2_3')}
      </p>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(HomePage),
})
