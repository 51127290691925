import { UserActions } from './user.actions'
import { UserState } from './user.state'

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading }
    case 'set-user-data':
      return { ...state, ...action.data }
    case 'set-user':
      return { ...state, user: action.user }
    case 'set-has-seen-tutorial':
      return { ...state, hasSeenTutorial: action.hasSeenTutorial }
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode }
    case 'set-is-authenticated':
      return { ...state, isAuthenticated: action.isAuthenticated }
    case 'set-lang':
      return { ...state, lang: action.lang }
    case 'set-net':
      return { ...state, net: action.net }
    case 'set-last-route':
      return { ...state, lastRoute: action.lastRoute }
    case 'set-app':
      return { ...state, app: action.app }
    case 'set-double-rendering':
      return { ...state, doubleRendering: action.doubleRendering }
    case 'set-currency':
      return { ...state, currency: action.currency }
    case 'set-hand':
      return { ...state, hand: action.hand }
  }
}
