import { Md5 } from 'ts-md5'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonText } from '@ionic/react'
import { IonLabel } from '@ionic/react'
import { IonItem, IonList } from '@ionic/react'
import { t } from 'i18next'
import SignOutButton from '../../components/auth/SignOutButton'
import { connect } from '../../data/connect'
import { User } from '../../models/User'
import PageContainer from '../PageContainer'
import './Account.scss'
import WebPushToggle from './WebPushToggle'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  user?: User
}
interface DispatchProps {}
interface AccountProps extends OwnProps, StateProps, DispatchProps {}

const Account: React.FC<AccountProps> = ({ user }) => {
  const [avatar, setAvatar] = useState<string>()

  function getGravatarURL(email: string) {
    const address = String(email).trim().toLowerCase()
    const hash = Md5.hashStr(address)
    return `https://www.gravatar.com/avatar/${hash}`
  }

  useEffect(() => {
    if (user?.email) setAvatar(getGravatarURL(user?.email))
  }, [user?.email])

  return (
    <PageContainer id='account-page' isPrivate={true} title='Account'>
      <div className='ion-padding-top ion-text-center'>
        {avatar && <img src={avatar} alt='avatar' />}
        <IonList inset>
          <IonItem>
            <IonLabel>{t<string>('Name')}</IonLabel>
            <IonText>{user?.given_name}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{t<string>('Family name')}</IonLabel>
            <IonText>{user?.family_name}</IonText>
          </IonItem>
          <IonItem>
            <IonLabel>{t<string>('Email')}</IonLabel>
            <IonText>{user?.email}</IonText>
          </IonItem>
          <WebPushToggle />
          <IonItem routerLink='/support' routerDirection='none'>
            {t<string>('Support')}
          </IonItem>
          <SignOutButton />
        </IonList>
      </div>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
  }),
  mapDispatchToProps: {},
  component: React.memo(Account),
})
