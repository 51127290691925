import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync'
import React, { FC, useEffect, useState } from 'react'
import { IonButtons, IonChip, IonFab, IonFabButton, IonSearchbar } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions, useIonModal } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { sync, trashBin } from 'ionicons/icons'
import { t } from 'i18next'
import { failure, info } from '../components/util/Toast'
import { fixKafYe } from '../components/util/Word'
import currencySign from '../components/util/currencySign'
import remoteErrorExtractor from '../components/util/remoteErrorExtractor'
import { connect } from '../data/connect'
import { setRefreshProductList, setSyncing } from '../data/factor/factor.actions'
import { App } from '../models/App'
import Product from '../models/gtin/Product'
import { GtinDbService } from '../services/GtinDbService'
import ProductEditModal from './ProductEditModal'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  syncing: number
  refreshProductList: boolean
}
interface DispatchProps {
  setSyncing: typeof setSyncing
  setRefreshProductList: typeof setRefreshProductList
}
interface ProductListProps extends OwnProps, StateProps, DispatchProps {}
const ProductList: FC<ProductListProps> = ({
  app,
  lang,
  syncing,
  setSyncing,
  refreshProductList,
  setRefreshProductList,
}) => {
  const database = GtinDbService.Instance.db
  const [toast] = useIonToast()
  const [products, setProducts] = useState<Product[]>([])
  const [deleting, setDeleting] = useState(false)
  const [syncNeeded, setSyncNeeded] = useState(false)
  const [gtin, setGtin] = useState<string>()
  const [showSyncButton, setShowSyncButton] = useState(false)
  const [present, dismiss] = useIonModal(ProductEditModal, {
    gtin,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  const [search, setSearch] = useState<string>()

  const fetchProducts = async () => {
    if (app?.publicId) {
      const prods: Product[] = await GtinDbService.Instance.searchProduct(app?.publicId, search)
      if (!!prods.length) {
        setProducts(prods.map((prod: Product) => prod.workaroundSetModel()))
        setShowSyncButton(false)
      } else {
        if (search) setProducts([])
        else setShowSyncButton(true)
      }
    }
  }

  const deleteProduct = async (product: Product) => {
    setDeleting(true)
    const prod: Product = await database.get<Product>('product').find(product.id)
    prod.markAsDeletedForReal()
    await fetchProducts()
    setDeleting(false)
  }

  async function checkUnsyncedChanges() {
    const res = await hasUnsyncedChanges({ database })
    setSyncNeeded(res)
  }

  async function productDbSync() {
    if (!app?.publicId) {
      info('Select an app first', toast)
      return
    }
    if (!!syncing) return
    const syncInterval = setInterval(() => {
      setSyncing((syncing += 0.05))
    }, 500)
    setTimeout(async () => {
      try {
        await GtinDbService.Instance.syncDb(app.publicId)
      } catch (err: any) {
        if (err?.response?.status !== 0) failure(remoteErrorExtractor(err), toast)
      }
      setRefreshProductList(!refreshProductList)
      clearInterval(syncInterval)
      setSyncing(0)
    }, 0)
  }

  useEffect(() => {
    if (syncNeeded) productDbSync()
  }, [syncNeeded])

  useEffect(() => {
    fetchProducts()
  }, [refreshProductList, search, app?.publicId])

  useEffect(() => {
    checkUnsyncedChanges()
  }, [])

  return (
    <>
      {showSyncButton && (
        <IonFab horizontal='center' vertical='top'>
          <IonFabButton onClick={productDbSync}>
            <IonIcon icon={sync} />
          </IonFabButton>
        </IonFab>
      )}
      <IonSearchbar
        debounce={500}
        placeholder={t('Search product')}
        onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}></IonSearchbar>
      {products.map((product: Product) => (
        <IonItemSliding key={product.id}>
          <IonItem
            button={true}
            onClick={() => {
              setGtin(product.gtin)
              present()
            }}>
            <IonLabel>
              {lang === 'fa' && (product.nameFa || product.name)}
              {lang !== 'fa' && (product.name || product.nameFa)}
              <p>{product.gtin}</p>
            </IonLabel>
            <IonButtons slot='end'>
              {!!product?.price && (
                <IonChip className='dir-ltr'>
                  {currencySign(product?.currency)} {(+product?.price).toLocaleString(navigator.language)}
                </IonChip>
              )}
            </IonButtons>
          </IonItem>
          <IonItemOptions>
            <IonItemOption color={'danger'} onClick={() => deleteProduct(product)} disabled={deleting}>
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    lang: state.user.lang,
    syncing: state.factor.syncing,
    refreshProductList: state.factor.refreshProductList,
  }),
  mapDispatchToProps: {
    setSyncing,
    setRefreshProductList,
  },
  component: React.memo(ProductList),
})
