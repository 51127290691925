import { valueOf } from '~build/time'

const map: any = {
  ﻙ: 'ک',
  ك: 'ک', // Note this line is different from previous line!
  ي: 'ی',
  ی: 'ی',
  ى: 'ی', // Note this line is different from previous line!
}

const fixKafYe = (text?: string) => {
  if (text) {
    return text.replace(/[كﻙيیى]/g, function (match: any) {
      return map[match]
    })
  }
  return text
}

export { fixKafYe }
