import React, { FC } from 'react'
import { IonSpinner } from '@ionic/react'

interface OwnProps {
  center?: boolean
}
interface TkSpinnerProps extends OwnProps {}
const TkSpinner: FC<TkSpinnerProps> = ({ center = true }) => {
  return (
    <>
      {center ? (
        <div className='ion-text-center'>
          <IonSpinner />
        </div>
      ) : (
        <IonSpinner />
      )}
    </>
  )
}

export default React.memo(TkSpinner)
