import { convertIsoToZoned } from '../../components/util/Date'
import TkDateTime from '../../components/util/TkDateTime'
import { failure } from '../../components/util/Toast'
import currencySign from '../../components/util/currencySign'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { setRefreshFactorList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Factor from '../../models/mfactor/Factor'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { RawFactorItem } from '../../models/mfactor/RawFactorItem'
import { TaxRecord } from '../../models/mfactor/TaxRecord'
import { GtinDbService } from '../../services/GtinDbService'
import { MFactorDbService } from '../../services/MFactorDbService'
import { MFactorService } from '../../services/MFactorService'
import { PersonOption } from '../person/PersonOption'
import PersonSelect from '../person/PersonSelect'
import ProductSelectCard from '../product/ProductSelectCard'
import FactorDeleteButton from './FactorDeleteButton'
import FactorTaxModal from './FactorTaxModal'
import FactorTotal from './FactorTotal'
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonText,
  useIonModal,
} from '@ionic/react'
import { IonItem, IonLabel, IonReorder, IonThumbnail } from '@ionic/react'
import { IonReorderGroup, ItemReorderEventDetail } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add, cloudUpload, trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

interface OwnProps {
  id: string
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  lang?: string
  currency?: string
  hand: 'right' | 'left'
  app?: App
  refreshFactorList: boolean
}
interface DispatchProps {
  setRefreshFactorList: typeof setRefreshFactorList
}
interface FactorEditModalProps extends OwnProps, StateProps, DispatchProps {}
const FactorEditModal: FC<FactorEditModalProps> = ({
  id,
  onDismiss,
  currency,
  lang,
  hand,
  app,
  refreshFactorList,
  setRefreshFactorList,
}) => {
  const database = MFactorDbService.Instance.db
  const [factor, setFactor] = useState<Factor | any>({
    id,
    factorType: 'REAL',
  })
  const [items, setItems] = useState<FactorItem[]>([])
  const [rawItems, setRawItems] = useState<RawFactorItem[]>([])
  const [saving, setSaving] = useState(false)
  const [factorPayer, setFactorPayer] = useState<PersonOption>()
  const [factorDate2, setFactorDate2] = useState<string>()
  const [editMode, setEditMode] = useState(false)
  const [sending, setSending] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [taxRecord, setTaxRecord] = useState<TaxRecord>()
  const [showWithDelay, setShowWithDelay] = useState(false)
  const [toast] = useIonToast()
  const [present, dismiss] = useIonModal(FactorTaxModal, {
    factor,
    taxRecord,
    onDismiss: (data: string, role: string) => {
      dismiss(data, role)
    },
  })

  const findById = async () => {
    setShowWithDelay(false)
    const f = await database
      .get<Factor>('factor')
      .find(id)
      .catch(() => null)
    if (f) {
      setFactor(f.workaroundSetModel())
      setFactorDate2(f.factorDate)
      if (f.detail) setRawItems(JSON.parse(f.detail)?.items || [])
    }
    setTimeout(() => {
      setShowWithDelay(true)
    }, 300)
  }

  const factorDetail = (): string => {
    const res: any[] = []
    for (let i = 0; i < items.length; i++) {
      res.push({
        r: i,
        i: items[i].id.split('_')[1],
        p: items[i].price,
        q: items[i].qty,
      })
    }
    return JSON.stringify({ items: res })
  }

  const store = async () => {
    setSaving(true)
    const existed = await database.write(async () => {
      const existed: Factor | null = await database
        .get<Factor>('factor')
        .find(factor._raw?.id)
      await existed.update(() => {
        existed.currency = items[0].currency
        existed.total = items.reduce((a: any, c: any) => a + c.qty * c.price, 0)
        existed.detail = factorDetail()
        existed.factorDate =
          convertIsoToZoned(factorDate2) || existed.factorDate
        existed.payer = factorPayer?.value
        existed.workaroundSetRaw()
      })
      return existed
    })
    onDismiss(existed as any, 'edited')
    setFactor(undefined)
    await setRefreshFactorList(!refreshFactorList)
    setSaving(false)
  }

  const handleReorder = (evt: CustomEvent<ItemReorderEventDetail>) => {
    const movedItem = items.splice(evt.detail.from, 1)[0]
    items.splice(evt.detail.to, 0, movedItem)
    setItems(items)
    evt.detail.complete()
  }

  const addFactorItem = (fItem: FactorItem) => {
    if (!items.find((fi: FactorItem) => fi.id === fItem.id)) {
      fItem.qty = fItem.qty ? fItem.qty : 1
      setItems([...items, fItem])
    } else {
      const elem: FactorItem | undefined = items.find(
        (fi: FactorItem) => fi.id === fItem.id
      )
      if (elem && (elem?.qty || 0) > 0) {
        elem.qty = elem?.qty + 1
        items.map((x: FactorItem) => (x.id === fItem.id ? { ...elem } : x))
        setItems([...items])
      }
    }
  }

  const removeFactorItem = (fItem: FactorItem) => {
    const elem = items.find((x: FactorItem) => x.id === fItem.id)
    if (elem && elem.qty === 1) {
      setItems([...items.filter((x: FactorItem) => x.id !== fItem.id)])
    } else if (elem && elem.qty > 1) {
      elem.qty = elem.qty - 1
      items.map((x: FactorItem) => (x.id === fItem.id ? { ...elem } : x))
      setItems([...items])
    }
  }

  const fetchFactorItems = async () => {
    const promises: Promise<FactorItem>[] = []
    rawItems.forEach((rfi: RawFactorItem) =>
      promises.push(
        GtinDbService.Instance.lookupFactorItem(
          rfi,
          factor.currency,
          app?.publicId,
          lang
        )
      )
    )
    setItems(items.concat.apply([], await Promise.all(promises)))
  }

  const sendFactorToTaxOffice = async (type: string) => {
    setSending(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.post(
        `/v1/tax-record/${app?.publicId}/${factor.id}/send/${type}`,
        {}
      )
      if (res.status === 200) onDismiss(null, 'cancel')
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setSending(false)
  }

  const fetchTaxRecord = async (factorPublicId: string) => {
    setFetching(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.get(
        `/v1/tax-record/${app?.publicId}/${factorPublicId}`
      )
      if (res.status === 200) setTaxRecord(res.data)
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setFetching(false)
  }

  useEffect(() => {
    if (factor?.paid && factor?.id) fetchTaxRecord(factor.id)
  }, [factor])

  useEffect(() => {
    fetchFactorItems()
  }, [rawItems])

  useEffect(() => {
    findById()
  }, [id])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              {t<string>('Cancel')}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {t<string>(editMode ? 'Edit factor' : 'View factor')}{' '}
            <CopyToClipboard text={factor?.id}>
              <span className='pointer'>{factor?.id}</span>
            </CopyToClipboard>
          </IonTitle>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton
              onClick={() => setEditMode(!editMode)}
              disabled={factor?.paid}
            >
              {editMode ? t<string>('View') : t<string>('Edit')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <FactorTotal factorItems={items} />
          </IonCol>
        </IonRow>
        <IonReorderGroup disabled={!editMode} onIonItemReorder={handleReorder}>
          {items.map((factorItem: FactorItem, idx: number) => (
            <IonItem
              key={factorItem.id}
              button={true}
              disabled={saving || !editMode}
            >
              <IonReorder slot='start' className='ion-no-margin'></IonReorder>
              <IonBadge className='ion-margin-end' color={'dark'}>
                {factorItem.qty}
              </IonBadge>
              <IonLabel onClick={() => addFactorItem(factorItem)}>
                {lang === 'fa' && (factorItem.nameFa || factorItem.name)}
                {lang !== 'fa' && (factorItem.name || factorItem.nameFa)}
                <p className='dir-ltr ion-padding-start'>
                  {' '}
                  {currencySign(factorItem.currency)}{' '}
                  {factorItem.price?.toLocaleString(navigator.language)}
                </p>
              </IonLabel>
              <IonButtons slot='end' className='ion-no-margin'>
                <IonButton
                  onClick={() => removeFactorItem(factorItem)}
                  color='danger'
                  disabled={saving}
                >
                  <IonIcon icon={trashBin}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonItem>
          ))}
        </IonReorderGroup>
        <PersonSelect
          defaultId={factor?.payer}
          onChange={(value?: PersonOption) => setFactorPayer(value)}
          disabled={!editMode}
        />
        {factorDate2 && showWithDelay && (
          <TkDateTime
            id='factor-edit-modal-date'
            defaultDate={factorDate2}
            onChange={(value?: string) => setFactorDate2(value)}
            calendarPopperPosition='bottom'
            disabled={!editMode}
          />
        )}
        <div className='ion-padding-vertical'></div>
        {editMode && (
          <ProductSelectCard
            factorItemList={items}
            addFactorItem={addFactorItem}
            factorSaving={saving}
          />
        )}
        {factor?.paid && (
          <>
            <IonItem>
              <IonLabel>{t<string>('Paid')}</IonLabel>
              <IonThumbnail slot='end'>
                {factor?.paidRef === 'IPG_SEP' && (
                  <img src='/assets/img/ipg/sep.png' alt='sep' />
                )}
              </IonThumbnail>
            </IonItem>
            <IonItem>
              <IonLabel>{t<string>('Tax record')}</IonLabel>
              {fetching && <IonSpinner slot='end' />}
              {!fetching && (
                <>
                  {taxRecord?.taxId && (
                    <>
                      <IonButton
                        fill='clear'
                        slot='end'
                        onClick={() => present()}
                      >
                        {taxRecord?.taxId}
                      </IonButton>
                      <IonButton
                        onClick={() => sendFactorToTaxOffice('CORRECTION')}
                        color={'medium'}
                        disabled={sending}
                      >
                        <IonIcon icon={cloudUpload} slot='icon-only' />
                      </IonButton>
                    </>
                  )}
                  {!taxRecord?.taxId && (
                    <IonButton
                      onClick={() => sendFactorToTaxOffice('MAIN')}
                      disabled={sending}
                    >
                      <IonIcon icon={cloudUpload} slot='icon-only' />
                    </IonButton>
                  )}
                </>
              )}
            </IonItem>
          </>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            {editMode && (
              <IonButton
                onClick={() => store()}
                disabled={saving}
                color='primary'
                fill='outline'
              >
                {!saving && <IonIcon icon={add} slot='start' />}
                {saving && <IonSpinner slot='start' />}
                {t<string>('Save')}
              </IonButton>
            )}
            {!editMode && (
              <IonButton onClick={() => onDismiss(null, 'cancel')}>
                {t<string>('Close')}
              </IonButton>
            )}
          </IonButtons>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <FactorDeleteButton
              disabled={factor?.paid}
              factor={factor}
              onDelete={(res: any) => {
                if (res) {
                  onDismiss(res, 'deleted')
                }
              }}
            />
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    currency: state.user.currency,
    hand: state.user.hand,
    app: state.user.app,
    refreshFactorList: state.factor.refreshFactorList,
  }),
  mapDispatchToProps: {
    setRefreshFactorList,
  },
  component: React.memo(FactorEditModal),
})
