// Web-Push
// Public base64 to Uint
const urlBase64ToUint8Array = (base64String?: string) => {
  if (!base64String) return
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/') // eslint-disable-line

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export { urlBase64ToUint8Array }
