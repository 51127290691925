import SignInButton from '../../components/auth/SignInButton'
import SignOutButton from '../../components/auth/SignOutButton'
import { connect } from '../../data/connect'
import { setScanBeep, setShowScanner } from '../../data/factor/factor.actions'
import { setDarkMode, setHand, setLang } from '../../data/user/user.actions'
import ProductListModal from '../../pages-gtin/ProductListModal'
import PersonListModal from '../../pages-mfactor/person/PersonListModal'
import SettingsModal from '../settings/SettingsModal'
import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react'
import {
  barcodeOutline,
  barcodeSharp,
  chevronDown,
  cube,
  handLeft,
  settings,
} from 'ionicons/icons'
import {
  handRight,
  moon,
  people,
  sunny,
  volumeHigh,
  volumeMute,
} from 'ionicons/icons'
import React, { FC } from 'react'

interface StateProps {
  isAuthenticated: boolean
  darkMode: boolean
  lang?: string
  hand: string
  scanBeep: boolean
  showScanner: boolean
}
interface DispatchProps {
  setDarkMode: typeof setDarkMode
  setLang: typeof setLang
  setHand: typeof setHand
  setScanBeep: typeof setScanBeep
  setShowScanner: typeof setShowScanner
}
interface DeskMenuFabProps extends StateProps, DispatchProps {}
const DeskMenuFab: FC<DeskMenuFabProps> = ({
  isAuthenticated,
  darkMode,
  setDarkMode,
  lang,
  setLang,
  hand,
  setHand,
  scanBeep,
  setScanBeep,
  showScanner,
  setShowScanner,
}) => {
  return (
    <>
      <IonFab slot='fixed' vertical='top' horizontal='end' edge={true}>
        <IonFabButton color={isAuthenticated ? 'success' : 'primary'}>
          <IonIcon icon={chevronDown}></IonIcon>
        </IonFabButton>
        <IonFabList side='bottom'>
          <IonFabButton id='open-settings' title='Settings'>
            <IonIcon icon={settings} />
          </IonFabButton>
          <IonFabButton id='open-product-list' title='Products and services'>
            <IonIcon icon={cube} />
          </IonFabButton>
          <IonFabButton id='open-person-list' title='People and companies'>
            <IonIcon icon={people} />
          </IonFabButton>
          {lang === 'en' && (
            <IonFabButton onClick={() => setLang('fa')} title='Switch to Farsi'>
              فا
            </IonFabButton>
          )}
          {lang === 'fa' && (
            <IonFabButton
              onClick={() => setLang('en')}
              title='Switch to English'
            >
              En
            </IonFabButton>
          )}
          {darkMode && (
            <IonFabButton
              onClick={() => setDarkMode(false)}
              title='Switch to light mode'
            >
              <IonIcon icon={sunny} />
            </IonFabButton>
          )}
          {!darkMode && (
            <IonFabButton
              onClick={() => setDarkMode(true)}
              title='Switch to dark mode'
            >
              <IonIcon icon={moon} />
            </IonFabButton>
          )}
          {!scanBeep && (
            <IonFabButton
              onClick={() => setScanBeep(true)}
              title='Scanner beep is mute'
            >
              <IonIcon icon={volumeMute} />
            </IonFabButton>
          )}
          {scanBeep && (
            <IonFabButton
              onClick={() => setScanBeep(false)}
              color='dark'
              title='Scanner beep is on'
            >
              <IonIcon icon={volumeHigh} />
            </IonFabButton>
          )}
          {!showScanner && (
            <IonFabButton
              onClick={() => setShowScanner(true)}
              title='Scanner button is hidden'
            >
              <IonIcon icon={barcodeSharp} />
            </IonFabButton>
          )}
          {showScanner && (
            <IonFabButton
              onClick={() => setShowScanner(false)}
              color='dark'
              title='Scanner button is visible'
            >
              <IonIcon icon={barcodeOutline} />
            </IonFabButton>
          )}
          {hand === 'right' && (
            <IonFabButton
              onClick={(evt: any) => {
                evt.preventDefault()
                evt.stopPropagation()
                setHand('left')
              }}
            >
              <IonIcon icon={handLeft} title='Switch to left hand' />
            </IonFabButton>
          )}
          {hand === 'left' && (
            <IonFabButton
              onClick={(evt: any) => {
                evt.preventDefault()
                evt.stopPropagation()
                setHand('right')
              }}
            >
              <IonIcon icon={handRight} title='Switch to right hand' />
            </IonFabButton>
          )}
          {!isAuthenticated && <SignInButton fab={true} />}
          {isAuthenticated && <SignOutButton fab={true} />}
        </IonFabList>
      </IonFab>
      <ProductListModal />
      <PersonListModal />
      <SettingsModal />
    </>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    darkMode: state.user.darkMode,
    lang: state.user.lang,
    hand: state.user.hand,
    scanBeep: state.factor.scanBeep,
    showScanner: state.factor.showScanner,
  }),
  mapDispatchToProps: {
    setDarkMode,
    setLang,
    setHand,
    setScanBeep,
    setShowScanner,
  },
  component: React.memo(DeskMenuFab),
})
