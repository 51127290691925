import React, { FC, useEffect, useState } from 'react'
import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { checkmark, openOutline } from 'ionicons/icons'
import { t } from 'i18next'
import { connect } from '../../../data/connect'
import { Product } from '../../../models/Product'

interface StateProps {
  products: Product[]
  lang?: string
}
interface ProductWebPushProps extends StateProps {}
const ProductWebPush: FC<ProductWebPushProps> = ({ products, lang }) => {
  const [product, setProduct] = useState<Product>()

  useEffect(() => {
    if (products) {
      const elem = products.find((p: Product) => p.id === 'web-push')
      if (elem) setProduct(elem)
    }
  }, [products])

  const features = (
    <IonCol>
      <IonList>
        {product?.features?.map((f: string) => (
          <IonItem key={f}>
            <IonLabel>{t<string>(f)}</IonLabel>
            <IonIcon icon={checkmark} color='success' slot='start'></IonIcon>
          </IonItem>
        ))}
      </IonList>
    </IonCol>
  )

  const paragraph = (
    <IonCol>
      <p>{t<string>('ProductWebPushP1')}</p>
    </IonCol>
  )

  return (
    <IonGrid>
      <IonRow>{features}</IonRow>
      <IonRow>
        <IonCol>
          <a href='https://docs.taakcloud.com/products/web-push-notification/' target={'_blank'}>
            {t<string>('Technical Document')}
            <IonIcon icon={openOutline} />
          </a>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    products: state.data.products,
    lang: state.user.lang,
  }),
  component: React.memo(ProductWebPush),
})
