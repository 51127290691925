import { fixKafYe } from '../components/util/Word'
// const { productdb } = lazily(() => import('../data/gtin/productdb'))
import { productdb } from '../data/gtin/productdb'
import Product from '../models/gtin/Product'
import { ProductNames } from '../models/gtin/ProductNames'
import { FactorItem } from '../models/mfactor/FactorItem'
import { RawFactorItem } from '../models/mfactor/RawFactorItem'
import { productNameWith } from '../pages-gtin/productNameOneLine'
import { GtinService } from './GtinService'
import { Database, Q } from '@nozbe/watermelondb'
import { synchronize } from '@nozbe/watermelondb/sync'
//import { lazily } from 'react-lazily'
import { AxiosResponse } from 'axios'

export class GtinDbService {
  private static gtinDbService: GtinDbService | undefined
  public db: Database
  public productCache

  constructor(productDb: Database) {
    this.db = productDb
    this.productCache = new Map<string, ProductNames>()
  }

  async incrementSoldCount(factorItems: FactorItem[]) {
    const prods = await this.db
      .get<Product>('product')
      .query(Q.where('id', Q.oneOf(factorItems.map((f: FactorItem) => f.id))))
      .fetch()
    for (let product of prods) {
      await product.soldOneMore()
    }
  }

  async searchProduct(appPublicId: string, search?: string) {
    return this.db.read(() => {
      const whereClause = []
      whereClause.push(Q.where('app_public_id', appPublicId))
      if (search)
        whereClause.push(
          Q.and(
            Q.or(
              Q.where('name', Q.like(`%${search}%`)),
              Q.where('name_fa', Q.like(`%${search}%`)),
              Q.where('gtin', Q.like(`%${search}%`))
            )
          )
        )
      return this.db
        ?.get<Product>('product')
        ?.query(...whereClause, Q.sortBy('update_at', Q.desc), Q.take(10))
        ?.fetch()
    })
  }

  async fetchProductIds(appPublicId: string, search?: string) {
    return this.db.read(() => {
      const whereClause = []
      whereClause.push(Q.where('app_public_id', appPublicId))
      if (search)
        whereClause.push(
          Q.and(
            Q.or(
              Q.where('name', Q.like(`%${search}%`)),
              Q.where('name_fa', Q.like(`%${search}%`))
            )
          )
        )
      return this.db
        ?.get<Product>('product')
        ?.query(...whereClause, Q.sortBy('update_at', Q.desc), Q.take(10))
        ?.fetchIds()
    })
  }

  fixFarsi(p: any) {
    p.name_fa = fixKafYe(p.name_fa)
    return p
  }

  fixFarsiChars(changes: any) {
    if (changes?.product?.created?.length)
      changes.product.created = changes.product.created.map((p: Product) =>
        this.fixFarsi(p)
      )
    if (changes?.product?.updated?.length)
      changes.product.updated = changes.product.updated.map((p: Product) =>
        this.fixFarsi(p)
      )
    return changes
  }

  async syncDb(appPublicId: string) {
    await synchronize({
      database: this.db,
      pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
        const urlParams = `last_pulled_at=${lastPulledAt}&schema_version=${schemaVersion}&migration=${encodeURIComponent(
          JSON.stringify(migration)
        )}`
        const response: AxiosResponse = await GtinService.Instance.get(
          `/v1/app-product/sync/${appPublicId}?${urlParams}`
        )
        const { changes, timestamp } = response.data
        return { changes: this.fixFarsiChars(changes), timestamp }
      },
      pushChanges: async ({ changes, lastPulledAt }) => {
        const res = await GtinService.Instance.post(
          `/v1/app-product/sync/${appPublicId}?last_pulled_at=${lastPulledAt}`,
          {
            changes,
          }
        )
      },
      migrationsEnabledAtVersion: 1,
      onWillApplyRemoteChanges: (info) => {
        return Promise.resolve()
      },
    })
  }
  async lookupFactorItem(
    rfi: RawFactorItem,
    currency: string,
    appPublicId?: string,
    lang?: string
  ): Promise<FactorItem> {
    if (this.productCache.has(rfi.i)) {
      return Promise.resolve({
        id: `${appPublicId}_${rfi.i}`,
        name: productNameWith(this.productCache.get(rfi.i), lang),
        currency,
        price: rfi.p,
        qty: rfi.q,
      })
    }
    const p: Product | null = await this.db.read(async () => {
      return await this.db
        .get<Product>('product')
        .find(`${appPublicId}_${rfi.i}`)
        .catch(() => null)
    })
    p?.workaroundSetModel()
    if (p?.name) {
      this.productCache.set(rfi.i, { name: p.name, nameFa: p.nameFa })
      return Promise.resolve({
        id: `${appPublicId}_${rfi.i}`,
        name: productNameWith({ name: p.name, nameFa: p.nameFa }, lang),
        currency,
        price: rfi.p,
        qty: rfi.q,
      })
    } else {
      const remoteProduct = await GtinService.Instance.get(
        `/v1/pip/${rfi.i}/${currency}`
      )
      if (remoteProduct.status === 200) {
        this.productCache.set(rfi.i, {
          name: remoteProduct.data.name,
          nameFa: remoteProduct.data.nameFa,
        })
        return Promise.resolve({
          id: `${appPublicId}_${rfi.i}`,
          name: productNameWith(
            {
              name: remoteProduct.data.name,
              nameFa: remoteProduct.data.nameFa,
            },
            lang
          ),
          currency,
          price: rfi.p,
          qty: rfi.q,
        })
      }
    }
    return Promise.resolve({
      id: `${appPublicId}_${rfi.i}`,
      name: 'Not found',
      currency,
      price: rfi.p,
      qty: rfi.q,
    })
  }

  private static buildDbInstance() {
    return new GtinDbService(productdb)
  }

  public static get Instance(): GtinDbService {
    if (!this.gtinDbService) {
      this.gtinDbService = this.buildDbInstance()
    }

    return this.gtinDbService
  }
}
