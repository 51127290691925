import { convertIsoToZoned } from '../../components/util/Date'
import { failure } from '../../components/util/Toast'
import { mainSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import {
  setFactorDate,
  setFactorItemList,
  setFactorPayer,
} from '../../data/factor/factor.actions'
import {
  setFactorSaving,
  setRefreshFactorList,
} from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Factor from '../../models/mfactor/Factor'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { GtinDbService } from '../../services/GtinDbService'
import { MFactorDbService } from '../../services/MFactorDbService'
import { PersonOption } from '../person/PersonOption'
import {
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSpinner,
  useIonToast,
} from '@ionic/react'
import { t } from 'i18next'
import { save } from 'ionicons/icons'
import { FC, memo } from 'react'

interface OwnProps {
  isFab?: boolean
}
interface StateProps {
  app?: App
  hand: 'right' | 'left'
  factorDate: string
  factorSaving: boolean
  factorPayer?: PersonOption
  refreshFactorList: boolean
  factorItemList: FactorItem[]
}
interface DispatchProps {
  setFactorDate: typeof setFactorDate
  setFactorPayer: typeof setFactorPayer
  setFactorSaving: typeof setFactorSaving
  setFactorItemList: typeof setFactorItemList
  setRefreshFactorList: typeof setRefreshFactorList
}
interface DeskSaveFabProps extends OwnProps, StateProps, DispatchProps {}
const DeskSaveFab: FC<DeskSaveFabProps> = ({
  hand,
  factorItemList,
  factorDate,
  factorPayer,
  factorSaving,
  setFactorDate,
  setFactorPayer,
  setFactorSaving,
  refreshFactorList,
  setFactorItemList,
  setRefreshFactorList,
  app,
  isFab = true,
}) => {
  const [toast] = useIonToast()

  const factorDetail = (): string => {
    const items: any[] = []
    for (let i = 0; i < factorItemList.length; i++) {
      const fi: any = {
        r: i,
        i: factorItemList[i].id.split('_')[1],
        p: factorItemList[i].price,
        q: factorItemList[i].qty,
      }
      if (factorItemList[i].taxIrStuffId)
        fi.iti = factorItemList[i].taxIrStuffId
      if (factorItemList[i].taxIrStuffDesc)
        fi.itn = factorItemList[i].taxIrStuffDesc
      console.log('fi', fi)
      items.push(fi)
    }
    return JSON.stringify({ items })
  }

  const storeFactor = async () => {
    if (app?.publicId) {
      setFactorSaving(true)
      await MFactorDbService.Instance.db.write(async () => {
        const factor: Factor = await MFactorDbService.Instance.db
          .get<Factor>('factor')
          .create((factor) => {
            factor.appPublicId = app?.publicId
            factor.currency = factorItemList[0].currency
            factor.total = factorItemList.reduce(
              (a: any, c: any) => a + c.qty * c.price,
              0
            )
            factor.detail = factorDetail()
            factor.factorDate = convertIsoToZoned(factorDate) || factorDate
            factor.payer = factorPayer?.value
            factor.workaroundSetRaw()
          })
        return factor
      })
      await GtinDbService.Instance.incrementSoldCount(factorItemList)
      await setFactorDate(undefined)
      await setFactorPayer(undefined)
      await setRefreshFactorList(!refreshFactorList)
      setTimeout(() => {
        setFactorItemList([])
        setFactorSaving(false)
      }, 1000)
    } else failure('Select an app first', toast)
  }

  return (
    <>
      {factorItemList.length > 0 && isFab && (
        <IonFab
          slot='fixed'
          vertical='bottom'
          horizontal={mainSlot(hand, document.documentElement.dir)}
        >
          <IonFabButton
            disabled={factorSaving}
            onClick={storeFactor}
            type='button'
          >
            {!factorSaving && <IonIcon icon={save} />}
            {factorSaving && <IonSpinner name='bubbles' />}
          </IonFabButton>
        </IonFab>
      )}
      {!isFab && (
        <IonButton
          disabled={factorSaving || factorItemList.length === 0}
          onClick={storeFactor}
          expand='block'
          fill='clear'
        >
          {!factorSaving && <IonIcon slot='start' icon={save} />}
          {factorSaving && <IonSpinner slot='start' name='bubbles' />}
          {t<string>('Save')}
        </IonButton>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    hand: state.user.hand,
    factorItemList: state.factor.factorItemList,
    factorDate: state.factor.factorDate,
    factorPayer: state.factor.factorPayer,
    factorSaving: state.factor.factorSaving,
    refreshFactorList: state.factor.refreshFactorList,
  }),
  mapDispatchToProps: {
    setFactorDate,
    setFactorPayer,
    setFactorSaving,
    setFactorItemList,
    setRefreshFactorList,
  },
  component: memo(DeskSaveFab),
})
