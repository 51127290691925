import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import { MFactorService } from '../../services/MFactorService'
import { IonButton, IonItem, IonLabel, IonTextarea } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'

interface StateProps {
  app?: App
}
interface PrivateKeyInputProps extends StateProps {}

const PrivateKeyInput: FC<PrivateKeyInputProps> = ({ app }) => {
  const [property, setProperty] = useState<string>()
  const [hasValue, setHasValue] = useState<boolean>()
  const [toast] = useIonToast()
  const fetchItem = async (appPublicId: string) => {
    const res: AxiosResponse = await MFactorService.Instance.get(
      `/v1/tax-profile/${appPublicId}/private-key`
    )
    if (res.status === 200) setHasValue(res.data)
  }

  const updateItem = async (value?: string) => {
    try {
      const res: AxiosResponse = await MFactorService.Instance.put(
        `/v1/tax-profile/${app?.publicId}/private-key`,
        { value }
      )
      if (res.status === 200) setHasValue(true)
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
  }

  useEffect(() => {
    if (app?.publicId) fetchItem(app?.publicId)
  }, [app])

  return (
    <>
      {hasValue && (
        <IonItem lines='none'>
          <IonLabel>{t<string>('Private key')}</IonLabel>
          <IonButton onClick={() => setHasValue(false)}>
            {t<string>('Edit')}
          </IonButton>
        </IonItem>
      )}
      {!hasValue && (
        <IonTextarea
          value={property}
          onIonInput={(e: any) => setProperty(e.detail.value)}
          onIonChange={(e: any) => updateItem(e.detail.value)}
          placeholder={t('Private key')}
          dir='ltr'
          debounce={1500}
          rows={3}
          cols={3}
        ></IonTextarea>
      )}
    </>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: PrivateKeyInput,
})
