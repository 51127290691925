import { appSchema, tableSchema } from '@nozbe/watermelondb'

export default appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: 'product',
      columns: [
        { name: 'gtin', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'name_fa', type: 'string' },
        { name: 'app_public_id', type: 'string' },
        { name: 'currency', type: 'string' },
        { name: 'price', type: 'number' },
        { name: 'is_price_dynamic', type: 'boolean' },
        { name: 'sold', type: 'number' },
        { name: 'tax_ir_stuff_id', type: 'string' },
        { name: 'tax_ir_stuff_desc', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
      ],
    }),
  ],
})
