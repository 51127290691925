import React from 'react'

const SplashLoading = () => {
  return (
    <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
      <img src='/assets/pwa/apple-splash-640-1136.jpg' alt='Taak' />
    </div>
  )
}

export default React.memo(SplashLoading)
