import { ConfState } from './conf.state'
import { SessionsActions } from './sessions.actions'

export const sessionsReducer = (state: ConfState, action: SessionsActions): ConfState => {
  switch (action.type) {
    case 'set-conf-loading':
      return { ...state, loading: action.isLoading }
    case 'set-conf-data':
      return { ...state, ...action.data }
    case 'set-search-text':
      return { ...state, searchText: action.searchText }
    case 'set-menu-enabled':
      return { ...state, menuEnabled: action.menuEnabled }
    case 'set-web-push':
      return { ...state, webPush: action.webPush }
    case 'set-scroll-top':
      return { ...state, scrollTop: action.scrollTop }
  }
}
