import { FC, lazy, memo, useEffect, useState } from 'react'
import { IonButton, IonCol, IonIcon, IonRow, useIonModal } from '@ionic/react'
import { business, personCircle } from 'ionicons/icons'
import { t } from 'i18next'
import '../../components/select.scss'
import TkSpinner from '../../components/util/TkSpinner'
import { fixKafYe } from '../../components/util/Word'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { MFactorDbService } from '../../services/MFactorDbService'
import PersonEditModal from './PersonEditModal'
import { PersonOption } from './PersonOption'

const CreatableSelect = lazy(() => import('react-select/creatable'))

interface OwnProps {
  defaultValue?: PersonOption
  defaultId?: string
  onChange: (value?: PersonOption) => void
  disabled?: boolean
}
interface StateProps {
  app?: App
  refreshPersonList: boolean
}
interface DispatchProps {}
interface PersonSelectProps extends OwnProps, StateProps, DispatchProps {}
const PersonSelect: FC<PersonSelectProps> = ({
  defaultValue,
  defaultId,
  onChange,
  app,
  refreshPersonList,
  disabled = false,
}) => {
  const [options, setOptions] = useState<PersonOption[]>()
  const [items, setItems] = useState<Person[]>([])
  const [selectedOption, setSelectedOption] = useState<PersonOption | undefined>(defaultValue)
  const [id, setId] = useState<string>()
  const [fetching, setFetching] = useState(false)
  const [present, dismiss] = useIonModal(PersonEditModal, {
    id,
    onDismiss: (data: any, role: string) => {
      dismiss(data, role)
      if (role === 'edited') {
        fetchOptions()
        setSelectedOption({ value: data._raw.id, label: data.name, personType: data.personType })
      }
    },
  })

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
  }

  const fetchOptions = async () => {
    setFetching(true)
    if (app?.publicId) {
      const res: any = await MFactorDbService.Instance.personList(app.publicId)
      if (res) {
        res.map((p: Person) => p.workaroundSetModel())
        setItems(res || [])
        setOptions(
          res.flatMap((itm: Person) => {
            return { value: itm._raw.id, label: itm.name, personType: itm.personType }
          })
        )
      }
    }
    setTimeout(() => {
      setFetching(false)
    }, 1000)
  }

  const handleCreate = async (inputValue: string) => {
    if (app?.publicId) {
      setFetching(true)
      const newPerson: Person = await MFactorDbService.Instance.db.write(async () => {
        const pe: Person = await MFactorDbService.Instance.db.get<Person>('person').create((pe) => {
          ;(pe.appPublicId = app.publicId), (pe.personType = 'REAL')
          pe.name = fixKafYe(inputValue) || inputValue
          pe.detail = '{}'
          pe.workaroundSetRaw()
        })
        return pe
      })

      const newOption: PersonOption = {
        value: newPerson._raw.id,
        label: newPerson.name,
        personType: newPerson.personType,
      }
      setFetching(false)
      setOptions((prev: any) => [...prev, newOption])
      setSelectedOption(newOption)
    }
  }

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    fetchOptions()
  }, [refreshPersonList, app?.publicId]) // eslint-disable-line

  useEffect(() => {
    if (defaultId && items) {
      const defaultPerson = items.find((p: Person) => p._raw.id === defaultId)
      if (defaultPerson)
        setSelectedOption({ value: defaultId, label: defaultPerson.name, personType: defaultPerson.personType })
    }
  }, [defaultId, items])

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <IonRow>
      <IonCol>
        <CreatableSelect
          isDisabled={disabled || fetching}
          isLoading={fetching}
          value={selectedOption}
          options={options}
          onChange={handleChange}
          onCreateOption={handleCreate}
          placeholder={t<string>('Payer')}
          isClearable={true}
          className='my-react-select-container'
          classNamePrefix='my-react-select'
        />
      </IonCol>
      {selectedOption && !fetching && (
        <IonCol size='2'>
          <IonButton
            fill='clear'
            onClick={() => {
              setId(selectedOption.value)
              present()
            }}>
            {selectedOption?.personType === 'REAL' && <IonIcon icon={personCircle} />}
            {selectedOption?.personType === 'COMPANY' && <IonIcon icon={business} />}
          </IonButton>
        </IonCol>
      )}
      {fetching && (
        <IonCol size='2'>
          <TkSpinner />
        </IonCol>
      )}
    </IonRow>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  component: memo(PersonSelect),
})
