import '../../components/select.scss'
import TkSpinner from '../../components/util/TkSpinner'
import useDebounce from '../../components/util/useDebounce'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import { TaxIrStuffOption } from '../../models/mfactor/TaxIrStuffOption'
import { TaxIrStuffType } from '../../models/mfactor/TaxIrStuffType'
import { GtinService } from '../../services/GtinService'
import { IonCol, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { t } from 'i18next'
import { FC, lazy, memo, useEffect, useState } from 'react'

const Select = lazy(() => import('react-select'))

interface OwnProps {
  defaultValue?: TaxIrStuffOption
  defaultId?: string
  defaultDesc?: string
  onChange: (value?: TaxIrStuffOption) => void
  disabled?: boolean
}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface TaxIrStuffSelectProps extends OwnProps, StateProps, DispatchProps {}
const TaxIrStuffSelect: FC<TaxIrStuffSelectProps> = ({
  defaultValue,
  defaultId,
  defaultDesc,
  onChange,
  app,
  disabled = false,
}) => {
  const [options, setOptions] = useState<TaxIrStuffOption[]>()
  const [items, setItems] = useState<TaxIrStuffOption[]>([])
  const [search, setSearch] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<
    TaxIrStuffOption | undefined
  >(defaultValue)
  const [fetching, setFetching] = useState(false)
  const [searching, setSearching] = useState(false)
  const [taxIrStuffType, setTaxIrStuffType] =
    useState<TaxIrStuffType>('SERVICE_ID_GENERAL')
  const searched = useDebounce(search, 2000)

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
  }

  const doSearch = async () => {
    setSearching(true)
    const res: any = await GtinService.Instance.get(
      `/v1/tax-ir/stuff/search/${encodeURIComponent(searched || '')}${
        !!taxIrStuffType ? `?type=${taxIrStuffType}` : ''
      }`
    )
    if (res) {
      setItems(res.data || [])
      setOptions(res.data || [])
    }
    setSearching(false)
  }

  const fetchOptions = async () => {
    setFetching(true)
    const res: any = await GtinService.Instance.get(
      `/v1/tax-ir/stuff/search/${encodeURIComponent(defaultDesc || '')}`
    )
    if (res) {
      setItems(res.data || [])
      setOptions(res.data)
    }
    setTimeout(() => {
      setFetching(false)
    }, 1000)
  }

  useEffect(() => {
    doSearch()
  }, [searched])

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (!!defaultDesc) fetchOptions()
  }, [defaultDesc]) // eslint-disable-line

  useEffect(() => {
    if (defaultId && items) {
      const defaultStuff = items.find(
        (p: TaxIrStuffOption) => p.value === defaultId
      )
      if (defaultStuff)
        setSelectedOption({
          value: defaultId,
          label: defaultStuff.label,
        })
    }
  }, [defaultId, items])

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <>
      <IonRow>
        <IonCol>
          <Select
            isDisabled={disabled || fetching}
            isLoading={fetching}
            value={selectedOption}
            options={options}
            onInputChange={(newValue) => setSearch(newValue)}
            onChange={handleChange}
            placeholder={t<string>('Tax IR Stuff')}
            isClearable={true}
            className='my-react-select-container'
            classNamePrefix='my-react-select'
          />
        </IonCol>
        {(fetching || searching) && (
          <IonCol size='2'>
            <TkSpinner />
          </IonCol>
        )}
      </IonRow>
      <IonRow>
        <IonCol>
          <IonSelect
            value={taxIrStuffType}
            onIonChange={(ev) => setTaxIrStuffType(ev.detail.value)}
          >
            <IonSelectOption value={'UNKNOWN'}>
              {t<string>('All')}
            </IonSelectOption>
            <IonSelectOption value={'KALA_ID_DEDICATED_INTERNAL_PRODUCTION'}>
              {t<string>('KALA_ID_DEDICATED_INTERNAL_PRODUCTION')}
            </IonSelectOption>
            <IonSelectOption value={'KALA_ID_DEDICATED_IMPORTED'}>
              {t<string>('KALA_ID_DEDICATED_IMPORTED')}
            </IonSelectOption>
            <IonSelectOption value={'SERVICE_ID_DEDICATED'}>
              {t<string>('SERVICE_ID_DEDICATED')}
            </IonSelectOption>
            <IonSelectOption value={'KALA_ID_GENERAL_INTERNAL_PRODUCTION'}>
              {t<string>('KALA_ID_GENERAL_INTERNAL_PRODUCTION')}
            </IonSelectOption>
            <IonSelectOption value={'KALA_ID_GENERAL_IMPORTED'}>
              {t<string>('KALA_ID_GENERAL_IMPORTED')}
            </IonSelectOption>
            <IonSelectOption value={'SERVICE_ID_GENERAL'}>
              {t<string>('SERVICE_ID_GENERAL')}
            </IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: memo(TaxIrStuffSelect),
})
