import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { setRefreshFactorList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Factor from '../../models/mfactor/Factor'
import { TaxRecord } from '../../models/mfactor/TaxRecord'
import { MFactorService } from '../../services/MFactorService'
import { IonButton, IonButtons, IonInput, IonItem } from '@ionic/react'
import { IonLabel, IonTextarea } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { codeDownload, copyOutline } from 'ionicons/icons'
import React, { FC, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

interface OwnProps {
  taxRecord: TaxRecord
  factor: Factor
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  lang?: string
  currency?: string
  hand: 'right' | 'left'
  app?: App
  refreshFactorList: boolean
}
interface DispatchProps {
  setRefreshFactorList: typeof setRefreshFactorList
}
interface FactorTaxModalProps extends OwnProps, StateProps, DispatchProps {}
const FactorTaxModal: FC<FactorTaxModalProps> = ({
  factor,
  taxRecord,
  onDismiss,
  hand,
  app,
}) => {
  const [taxRecordData, setTaxRecordData] = useState<TaxRecord>(taxRecord)
  const [inprogress, setInprogress] = useState(false)
  const [toast] = useIonToast()

  const inquiryInvoice = async () => {
    setInprogress(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.get(
        `/v1/tax-record/${app?.publicId}/${factor.id}/inquiry`,
        {}
      )
      if (res.status === 200) setTaxRecordData(res.data)
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setInprogress(false)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              {t<string>('Cancel')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t<string>('Inquiry Tax Record')}</IonTitle>
          <IonButtons
            slot={mainSlot(hand, document.documentElement.dir)}
          ></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CopyToClipboard text={taxRecord.taxId || ''}>
          <IonItem>
            <IonInput
              value={taxRecord.taxId}
              label='taxId'
              readonly={true}
            ></IonInput>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon icon={copyOutline} slot='icon-only' />
              </IonButton>
            </IonButtons>
          </IonItem>
        </CopyToClipboard>
        <CopyToClipboard text={taxRecord.referenceNumber || ''}>
          <IonItem>
            <IonInput
              value={taxRecord.referenceNumber}
              label='referenceNumber'
              readonly={true}
            ></IonInput>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon icon={copyOutline} slot='icon-only' />
              </IonButton>
            </IonButtons>
          </IonItem>
        </CopyToClipboard>
        <IonItem>
          <IonLabel>{t<string>('InquiryData')}</IonLabel>
          <IonTextarea
            value={
              !!taxRecordData.responseData
                ? JSON.stringify(
                    JSON.parse(taxRecordData.responseData),
                    null,
                    2
                  )
                : taxRecordData.responseData
            }
            readonly={true}
            rows={7}
          ></IonTextarea>
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton
              onClick={inquiryInvoice}
              disabled={inprogress}
              color='primary'
              fill='outline'
            >
              {!inprogress && <IonIcon icon={codeDownload} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Inquiry')}
            </IonButton>
          </IonButtons>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>
              {t<string>('Close')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    currency: state.user.currency,
    hand: state.user.hand,
    app: state.user.app,
    refreshFactorList: state.factor.refreshFactorList,
  }),
  mapDispatchToProps: {
    setRefreshFactorList,
  },
  component: React.memo(FactorTaxModal),
})
