import React, { FC, useState } from 'react'
import { IonFabButton, IonIcon, IonItem } from '@ionic/react'
import { logOut } from 'ionicons/icons'
import { t } from 'i18next'
import { Auth } from '../../services/AuthService'

interface SignOutButtonProps {
  fab?: boolean
}
const SignInButton: FC<SignOutButtonProps> = ({ fab = false }) => {
  const [inprogress, setInprogress] = useState(false)

  const handleSignOut = async (e: any) => {
    setInprogress(true)
    e.preventDefault()
    await Auth.Instance.signOut()
      .then()
      .catch(() => {})
    setInprogress(false)
  }

  return (
    <>
      {fab ? (
        <IonFabButton onClick={handleSignOut} disabled={inprogress} title='Logout'>
          <IonIcon icon={logOut} color='danger'></IonIcon>
        </IonFabButton>
      ) : (
        <IonItem onClick={handleSignOut} button={true} disabled={inprogress}>
          {t<string>('Logout')}
        </IonItem>
      )}
    </>
  )
}

export default React.memo(SignInButton)
