import React, { FC, useState } from 'react'
import { IonItem, IonLabel, IonModal } from '@ionic/react'
import { t } from 'i18next'
import { App } from '../../models/App'
import ApiKeyEdit from './ApiKeyEdit'

interface OwnProps {
  onSuccess?: (app: App) => void
}
interface ApiKeyEditItemProps extends OwnProps {}
const ApiKeyEditItem: FC<ApiKeyEditItemProps> = ({ onSuccess }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <IonItem button onClick={() => setShowModal(true)}>
        <IonLabel className='ion-text-wrap'>
          {t<string>('API Key')}
          <p>{t<string>('Identifies your project using a simple API key to check quota and access')}</p>
        </IonLabel>
      </IonItem>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} id='app-edit-modal'>
        <ApiKeyEdit
          onClose={(apiKey: any) => {
            setShowModal(false)
            if (!!onSuccess) onSuccess(apiKey)
          }}
        />
      </IonModal>
    </>
  )
}

export default React.memo(ApiKeyEditItem)
