import { t } from 'i18next'
import { AxiosError } from 'axios'
import { error } from './Log'

const remoteErrorExtractor = (err: AxiosError | any) => {
  error('remoteError', err)
  if (err instanceof AxiosError) {
    const axiosError: AxiosError = err
    if (axiosError.response?.status === 0) {
      return t(err.message)
    } else if (axiosError.response?.status === 400) {
      return `${t(err.response?.data?.title)} "${t(err.response?.data?.violations?.[0]?.field)}" ${t(
        err.response?.data?.violations?.[0]?.message
      )}`
    } else {
      return t(err.message)
    }
  } else if (err instanceof Error) {
    const genericError: Error = err
    return t(genericError.message)
  } else {
    return JSON.stringify(err)
  }
}

export default remoteErrorExtractor
