import React, { FC, useState } from 'react'
import { IonItem, IonLabel, IonPopover, IonRow } from '@ionic/react'
import { IonButton, IonCol, IonContent, IonGrid, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import { t } from 'i18next'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import AppContainer from '../AppContainer'
import ApiKeyEditItem from '../api-key/ApiKeyEditItem'
import ApiKeyList from '../api-key/ApiKeyList'
import './AppCredentials.scss'

interface StateProps {
  app?: App
}
interface AppCredentialsProps extends StateProps {}
const AppCredentials: FC<AppCredentialsProps> = ({ app }) => {
  const [reloadApiKey, setReloadApiKey] = useState(false)

  const actionButtonsEnd = (
    <>
      <IonButton color={'primary'} id='add-trigger'>
        <IonIcon icon={add} slot='start'></IonIcon>
        {t('Create Credentials').toUpperCase()}
      </IonButton>
      <IonPopover trigger='add-trigger' size='auto'>
        <IonContent class='ion-padding'>
          <IonItem button disabled>
            <IonLabel className='ion-text-wrap'>
              Client Secrets
              <p>
                A secret string that the application uses to prove its identity. Also can be referred to as application
                password.
              </p>
            </IonLabel>
          </IonItem>
          <ApiKeyEditItem onSuccess={() => setReloadApiKey(!reloadApiKey)} />
        </IonContent>
      </IonPopover>
    </>
  )

  return (
    <AppContainer id='app-credentials' title='Credentials' actionButtonsEnd={actionButtonsEnd}>
      <h3>Client Secrets</h3>
      <IonGrid>
        <IonRow className='tr-header'>
          <IonCol>Description</IonCol>
          <IonCol>Expires</IonCol>
          <IonCol>Value</IonCol>
          <IonCol>Secret ID</IonCol>
          <IonCol>Actions</IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            No Client Secrets to display
            <hr className='hr' />
          </IonCol>
        </IonRow>
      </IonGrid>
      <ApiKeyList reload={reloadApiKey} />
    </AppContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: React.memo(AppCredentials),
})
