import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import { MFactorService } from '../../services/MFactorService'
import { IonInput, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'

interface StateProps {
  app?: App
}
interface MemoryIdInputProps extends StateProps {}

const MemoryIdInput: FC<MemoryIdInputProps> = ({ app }) => {
  const [memoryId, setMemoryId] = useState<string>()
  const [toast] = useIonToast()
  const fetchItem = async (appPublicId: string) => {
    const res: AxiosResponse = await MFactorService.Instance.get(
      `/v1/tax-profile/${appPublicId}/memory-id`
    )
    if (res.status === 200) setMemoryId(res.data)
  }

  const updateItem = async (value?: string) => {
    try {
      const res: AxiosResponse = await MFactorService.Instance.put(
        `/v1/tax-profile/${app?.publicId}/memory-id`,
        { value }
      )
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
  }

  useEffect(() => {
    if (app?.publicId) fetchItem(app?.publicId)
  }, [app])
  return (
    <IonInput
      value={memoryId}
      onIonInput={(e: any) => setMemoryId(e.detail.value)}
      onIonChange={(e: any) => updateItem(e.detail.value)}
      label={t('Memory Id')}
      placeholder={t('Memory Id')}
      dir='ltr'
      debounce={1500}
    ></IonInput>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: MemoryIdInput,
})
