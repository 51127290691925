import React from 'react'
import { ActionType } from '../../util/types'
import { getConfData, setWebPushData } from '../dataApi'
import { ConfState } from './conf.state'

export const loadConfData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true))
  const data = await getConfData()
  dispatch(setData(data))
  dispatch(setLoading(false))
}

export const setLoading = (isLoading: boolean) =>
  ({
    type: 'set-conf-loading',
    isLoading,
  } as const)

export const setData = (data: Partial<ConfState>) =>
  ({
    type: 'set-conf-data',
    data,
  } as const)

export const setSearchText = (searchText?: string) =>
  ({
    type: 'set-search-text',
    searchText,
  } as const)

export const setMenuEnabled = (menuEnabled: boolean) =>
  ({
    type: 'set-menu-enabled',
    menuEnabled,
  } as const)

export const setWebPush = (webPush: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setWebPushData(webPush)
  return {
    type: 'set-web-push',
    webPush,
  } as const
}

export const setScrollTop = (scrollTop: number) =>
  ({
    type: 'set-scroll-top',
    scrollTop,
  } as const)

export type SessionsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setSearchText>
  | ActionType<typeof setMenuEnabled>
  | ActionType<typeof setWebPush>
  | ActionType<typeof setScrollTop>
