import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync'
import React, { FC, useEffect, useState } from 'react'
import { IonFab, IonFabButton, IonSearchbar } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions, useIonModal } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { business, personCircle, sync, trashBin } from 'ionicons/icons'
import { t } from 'i18next'
import { failure, info } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setRefreshPersonList, setSyncing } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { MFactorDbService } from '../../services/MFactorDbService'
import PersonEditModal from './PersonEditModal'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  syncing: number
  refreshPersonList: boolean
}
interface DispatchProps {
  setSyncing: typeof setSyncing
  setRefreshPersonList: typeof setRefreshPersonList
}
interface PersonListProps extends OwnProps, StateProps, DispatchProps {}
const PersonList: FC<PersonListProps> = ({
  app,
  lang,
  setSyncing,
  syncing,
  refreshPersonList,
  setRefreshPersonList,
}) => {
  const database = MFactorDbService.Instance.db
  const [toast] = useIonToast()
  const [people, setPeople] = useState<Person[]>([])
  const [deleting, setDeleting] = useState(false)
  const [syncNeeded, setSyncNeeded] = useState(false)
  const [id, setId] = useState<string>()
  const [showSyncButton, setShowSyncButton] = useState(false)
  const [present, dismiss] = useIonModal(PersonEditModal, {
    id,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  const [search, setSearch] = useState<string>()

  const fetchPeople = async () => {
    if (app?.publicId) {
      const peps: Person[] = await MFactorDbService.Instance.personList(app?.publicId, search)
      if (!!peps?.length) {
        setPeople(peps.map((pep: Person) => pep.workaroundSetModel()))
        setShowSyncButton(false)
      } else {
        if (search) setPeople([])
        else setShowSyncButton(true)
      }
    }
  }

  const deletePerson = async (person: Person) => {
    setDeleting(true)
    const pep: Person = await database.get<Person>('person').find(person.id)
    pep.markAsDeletedForReal()
    await fetchPeople()
    setDeleting(false)
  }

  async function checkUnsyncedChanges() {
    const res = await hasUnsyncedChanges({ database })
    setSyncNeeded(res)
  }

  async function personDbSync() {
    if (!app?.publicId) {
      info('Select an app first', toast)
      return
    }
    if (!!syncing) return
    const syncInterval = setInterval(() => {
      setSyncing((syncing += 0.05))
    }, 500)
    setTimeout(async () => {
      try {
        await MFactorDbService.Instance.syncDb(app.publicId)
      } catch (err: any) {
        if (err?.response?.status !== 0) failure(remoteErrorExtractor(err), toast)
      }
      setRefreshPersonList(!refreshPersonList)
      clearInterval(syncInterval)
      setSyncing(0)
    }, 0)
  }

  useEffect(() => {
    if (syncNeeded) personDbSync()
  }, [syncNeeded])

  useEffect(() => {
    fetchPeople()
  }, [refreshPersonList, search, app?.publicId])

  useEffect(() => {
    checkUnsyncedChanges()
  }, [])

  return (
    <>
      {showSyncButton && (
        <IonFab horizontal='center' vertical='top'>
          <IonFabButton onClick={personDbSync}>
            <IonIcon icon={sync} />
          </IonFabButton>
        </IonFab>
      )}
      <IonSearchbar
        debounce={500}
        placeholder={t('Search person')}
        onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}></IonSearchbar>
      {people.map((p: Person) => (
        <IonItemSliding key={p.id}>
          <IonItem
            button={true}
            onClick={() => {
              setId(p.id)
              present()
            }}>
            {p.personType === 'REAL' && <IonIcon slot='start' icon={personCircle} />}
            {p.personType === 'COMPANY' && <IonIcon slot='start' icon={business} />}
            <IonLabel title={p.id}>{p.name}</IonLabel>
          </IonItem>
          <IonItemOptions>
            <IonItemOption color={'danger'} onClick={() => deletePerson(p)} disabled={deleting}>
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    lang: state.user.lang,
    syncing: state.factor.syncing,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  mapDispatchToProps: {
    setSyncing,
    setRefreshPersonList,
  },
  component: React.memo(PersonList),
})
