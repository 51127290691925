import React from 'react'
import { App } from '../../models/App'
import { User } from '../../models/User'
import { ActionType } from '../../util/types'
import { getUserData, setIsAuthenticatedData, setUserData, setHasSeenTutorialData } from '../dataApi'
import { setLastRouteData, setAppData, setCurrencyData, setHandData } from '../dataApi'
import { setDarkModeData, setLangData } from '../dataApi'
import { UserState } from './user.state'

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true))
  const data = await getUserData()
  dispatch(setData(data))
  dispatch(setLoading(false))
}

export const setLoading = (isLoading: boolean) =>
  ({
    type: 'set-user-loading',
    isLoading,
  } as const)

export const setData = (data: Partial<UserState>) =>
  ({
    type: 'set-user-data',
    data,
  } as const)

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsAuthenticatedData(false)
  dispatch(setUser())
}

export const setIsAuthenticated = (isAuthenticated: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setIsAuthenticatedData(isAuthenticated)
  return {
    type: 'set-is-authenticated',
    isAuthenticated,
  } as const
}

export const setDoubleRendering = (doubleRendering: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-double-rendering',
    doubleRendering,
  } as const
}

export const setUser = (user?: User) => async (dispatch: React.Dispatch<any>) => {
  await setUserData(user)
  return {
    type: 'set-user',
    user,
  } as const
}

export const setHasSeenTutorial = (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenTutorialData(hasSeenTutorial)
  return {
    type: 'set-has-seen-tutorial',
    hasSeenTutorial,
  } as const
}

export const setDarkMode = (darkMode: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setDarkModeData(darkMode)
  return {
    type: 'set-dark-mode',
    darkMode,
  } as const
}

export const setLang = (lang?: string) => async (dispatch: React.Dispatch<any>) => {
  await setLangData(lang)
  return {
    type: 'set-lang',
    lang,
  } as const
}

export const setCurrency = (currency?: string) => async (dispatch: React.Dispatch<any>) => {
  await setCurrencyData(currency)
  return {
    type: 'set-currency',
    currency,
  } as const
}

export const setNet = (net: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-net',
    net,
  } as const
}

export const setLastRoute = (lastRoute?: string) => async (dispatch: React.Dispatch<any>) => {
  await setLastRouteData(lastRoute)
  return {
    type: 'set-last-route',
    lastRoute,
  } as const
}

export const setApp = (app?: App | null) => async (dispatch: React.Dispatch<any>) => {
  await setAppData(app)
  return {
    type: 'set-app',
    app,
  } as const
}

export const setHand = (hand: 'right' | 'left') => async (dispatch: React.Dispatch<any>) => {
  await setHandData(hand)
  return {
    type: 'set-hand',
    hand,
  } as const
}

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsAuthenticated>
  | ActionType<typeof setUser>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setLang>
  | ActionType<typeof setNet>
  | ActionType<typeof setLastRoute>
  | ActionType<typeof setApp>
  | ActionType<typeof setDoubleRendering>
  | ActionType<typeof setCurrency>
  | ActionType<typeof setHand>
