import React, { FC, useRef } from 'react'
import { IonButton, IonButtons, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import { t } from 'i18next'

interface ActionModalProps {
  id: string
  isOpen: boolean
  onDidDismiss: (event: CustomEvent<OverlayEventDetail>) => void
  title?: string
  actionButtonsEnd?: any
  children?: any
}
const ActionModal: FC<ActionModalProps> = ({ id, isOpen, children, onDidDismiss, title = '', actionButtonsEnd }) => {
  const modal = useRef<HTMLIonModalElement>(null)

  return (
    <IonModal ref={modal} id={`${id}-action-modal`} isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => modal.current?.dismiss()}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot='end'>{actionButtonsEnd}</IonButtons>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonModal>
  )
}

export default React.memo(ActionModal)
