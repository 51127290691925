const map: any = {
  '۱': '1',
  '۲': '2',
  '۳': '3',
  '۴': '4',
  '۵': '5',
  '۶': '6',
  '۷': '7',
  '۸': '8',
  '۹': '9',
  '۰': '0',
  '١': '1',
  '٢': '2',
  '٣': '3',
  '٤': '4',
  '٥': '5',
  '٦': '6',
  '٧': '7',
  '٨': '8',
  '٩': '9',
  '٠': '0',
}

export function convertToEnglish(value: any) {
  if (!value) {
    return value
  }
  return value.replace(/[۰۱۲۳۴۵۶۷۸۹١٢٣٤٥٦٧٨٩٠]/g, function (match: any) {
    return map[match]
  })
}

const rmap: any = {
  1: '۱',
  2: '۲',
  3: '۳',
  4: '۴',
  5: '۵',
  6: '۶',
  7: '۷',
  8: '۸',
  9: '۹',
  0: '۰',
}

export function convertToFarsi(value: any) {
  if (!value) {
    return value
  }
  return ('' + value).replace(/[0123456789]/g, function (match) {
    return rmap[match]
  })
}
