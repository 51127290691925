import ActionModal from '../components/ActionModal'
import { error } from '../components/util/Log'
import TkSpinner from '../components/util/TkSpinner'
import { failure } from '../components/util/Toast'
import useDebounce from '../components/util/useDebounce'
import { connect } from '../data/connect'
import { setNet } from '../data/user/user.actions'
import { App } from '../models/App'
import { Uptime } from '../models/uptime/Uptime'
import AppContainer from '../pages/AppContainer'
import WebPushSubscribe from '../pages/web-push/WebPushSubscribe'
import { UptimeService } from '../services/UptimeService'
import UptimeEdit from './UptimeEdit'
import UptimeEditButton from './UptimeEditButton'
import { IonButton, IonChip, IonCol, IonIcon } from '@ionic/react'
import { IonItem, IonLabel, IonRow } from '@ionic/react'
import { IonSearchbar, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { refresh } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  onSuccess?: Function
}
interface StateProps {
  net: boolean
  app: App
}
interface DispatchProps {
  setNet: typeof setNet
}
interface UptimeListProps extends OwnProps, StateProps, DispatchProps {}
const UptimeList: FC<UptimeListProps> = ({ onSuccess, app, net, setNet }) => {
  const [items, setItems] = useState([])
  const [item, setItem] = useState<Uptime>()
  const [present] = useIonToast()
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const searched = useDebounce(search)

  const fetchItems = async () => {
    setNet(true)
    setItems([])
    try {
      const res: AxiosResponse = await UptimeService.Instance.get(
        `/v1/uptime/${app?.publicId}${!!searched ? `?name=${searched}` : ''}`
      )
      if (res.status === 200) {
        setItems(res.data)
      }
    } catch (err: any) {
      failure(err?.message, present)
      error('Apps#fetchItems', err)
    }
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
    return () => {}
  }, [searched]) // eslint-disable-line

  return (
    <AppContainer
      id='uptime-list-page'
      title={t('Uptime list')}
      actionButtonsEnd={<UptimeEditButton />}
    >
      <WebPushSubscribe />
      {net && <TkSpinner />}
      {!net && (
        <IonRow>
          <IonCol>
            <IonSearchbar
              onIonInput={(e: any) =>
                setSearch(e.detail.value?.toLocaleLowerCase())
              }
            />
          </IonCol>
          <IonCol size='2'>
            <IonButton fill='clear' onClick={fetchItems} disabled={net}>
              <IonIcon icon={refresh} slot='icon-only' />
            </IonButton>
          </IonCol>
        </IonRow>
      )}
      {!net &&
        items?.map((up: Uptime) => (
          <IonItem
            key={up.id}
            button
            onClick={() => {
              setItem(up)
              setShowModal(true)
            }}
          >
            <IonLabel>
              {up.name}
              <p>{up.target}</p>
            </IonLabel>
            <IonChip
              color={
                up.status === 'UP'
                  ? 'success'
                  : up.status === 'DOWN'
                  ? 'danger'
                  : 'medium'
              }
            >
              {up.status === 'UP'
                ? 'UP'
                : up.status === 'DOWN'
                ? 'DOWN'
                : 'UNKNOWN'}
            </IonChip>
          </IonItem>
        ))}
      <ActionModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        id='uptime-new'
      >
        <UptimeEdit
          uptime={item}
          onSuccess={async (up: Uptime) => {
            setShowModal(false)
            if (!!onSuccess) {
              onSuccess(up)
            }
            fetchItems()
          }}
        />
      </ActionModal>
    </AppContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
    app: state.user.app,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: React.memo(UptimeList),
})
