import React, { FC, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IonIcon, IonItem, IonLabel, IonText, IonThumbnail, useIonToast, useIonViewDidEnter } from '@ionic/react'
import { clipboard } from 'ionicons/icons'
import { t } from 'i18next'
import { filter, switchMap, take } from 'rxjs/operators'
import { AxiosResponse } from 'axios'
import { error, log } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setNet } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { AppApiService } from '../../services/AppApiService'
import { Auth } from '../../services/AuthService'
import PageContainer from '../PageContainer'
import AppEditButton from './AppEditButton'
import AppList from './AppList'
import AppListButton from './AppListButton'

interface StateProps {
  net: boolean
  app: App
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AppsProps extends StateProps, DispatchProps {}
const Apps: FC<AppsProps> = ({ net, setNet, app }) => {
  const [items, setItems] = useState<App | any>([])
  const [present] = useIonToast()
  const [copied, setCopied] = useState(false)

  const fetchItems = async () => {
    setNet(true)
    try {
      const res: AxiosResponse = await AppApiService.Instance.get('/v1/apps')
      if (res.status === 200) {
        setItems(res.data)
      }
      log('Response', res.data)
    } catch (err: any) {
      failure(err?.message, present)
      error('Apps#fetchItems', err)
    }
    setNet(false)
  }

  useIonViewDidEnter(() => {
    Auth.Instance.initComplete$
      .pipe(
        filter((complete) => complete),
        switchMap(() => Auth.Instance.isAuthenticated$),
        take(1)
      )
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          fetchItems()
        }
      })
  })

  useEffect(() => {
    fetchItems()
  }, [])

  return (
    <PageContainer
      id='apps-page'
      title={!app ? 'Apps' : ''}
      isPrivate={true}
      actionButtonsEnd={!app && <AppEditButton />}
      actionButtonsStart={!!app && <AppListButton />}>
      {!net && !app && items.length === 0 && <IonText>No app found, use {<AppEditButton />} to create one.</IonText>}
      {!app && <AppList />}
      {!!app && (
        <>
          <IonItem lines='none'>
            <IonThumbnail>
              <img alt='' src='../../assets/img/appicon.svg' />
            </IonThumbnail>
            <h1 className='ion-margin-bottom'>{t<string>('Welcome')}</h1>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel>
              <IonText className='ion-margin-bottom' style={{ fontSize: '125%' }}>
                {t<string>("You're working in")} <IonText color='primary'>{app?.name}</IonText>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel className='ion-text-wrap'>
              <p>
                <IonText className='text-bold'>{t<string>('App ID')}</IonText>
                <IonText className='ion-padding-start'>{app?.publicId}</IonText>
                <CopyToClipboard text={app?.publicId} onCopy={() => setCopied(true)}>
                  <IonIcon className='ion-padding-start' icon={clipboard} />
                </CopyToClipboard>{' '}
                {copied && <span className='ion-padding-start'>{t<string>('Copied!')}</span>}
              </p>
            </IonLabel>
          </IonItem>
        </>
      )}
    </PageContainer>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
    app: state.user.app,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: React.memo(Apps),
})
