import React, { FC } from 'react'
import { IonButton, IonIcon, useIonModal } from '@ionic/react'
import { add } from 'ionicons/icons'
import { t } from 'i18next'
import ProductEditModal from './ProductEditModal'

interface ProductAddButtonProps {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
const ProductAddButton: FC<ProductAddButtonProps> = ({ onDismiss }) => {
  const [present, dismiss] = useIonModal(ProductEditModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  return (
    <>
      <IonButton onClick={() => present()}>
        <IonIcon icon={add} slot='start' />
        {t<string>('New product')}
      </IonButton>
    </>
  )
}

export default React.memo(ProductAddButton)
