export type PlanPeriod = 'ONE_MONTH' | 'THREE_MONTHS' | 'SIX_MONTHS' | 'ONE_YEAR'

const planPeriodMonths = (pp: PlanPeriod): number => {
  switch (pp) {
    case 'ONE_MONTH':
      return 1
    case 'THREE_MONTHS':
      return 3
    case 'SIX_MONTHS':
      return 6
    case 'ONE_YEAR':
      return 12
    default:
      throw new Error('Months not found for ' + pp)
  }
}

export { planPeriodMonths }
