import React, { FC, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { caretDown, search } from 'ionicons/icons'
import { t } from 'i18next'
import Modal from '../../components/Modal'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import AppEditButton from './AppEditButton'
import AppList from './AppList'

interface StateProps {
  app?: App
}
interface OwnProps {
  hideSearchIcon?: boolean
}
interface AppListButtonProps extends OwnProps, StateProps {}
const AppListButton: FC<AppListButtonProps> = ({ app, hideSearchIcon = false }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <IonButton onClick={() => setShowModal(true)} className='ion-margin-bottom'>
        {!hideSearchIcon && <IonIcon icon={search} slot='start'></IonIcon>}
        {app?.name}
        <IonIcon icon={caretDown} slot='end'></IonIcon>
      </IonButton>
      <Modal
        id='app-list'
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        title={t<string>('Select App')}
        actionButtonsEnd={<AppEditButton onSuccess={(app: App) => setShowModal(false)} />}>
        <AppList
          onSuccess={async () => {
            setShowModal(false)
          }}
        />
      </Modal>
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: React.memo(AppListButton),
})
