import React, { FC, useEffect, useState } from 'react'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, useIonAlert, useIonToast } from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { AxiosResponse } from 'axios'
import { utcToLocaleDate } from '../../components/util/Date'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { ApiKey } from '../../models/ApiKey'
import { App } from '../../models/App'
import { AppApiService } from '../../services/AppApiService'

interface OwnProps {
  reload: boolean
}
interface StateProps {
  app?: App
}
interface ApiKeyListProps extends OwnProps, StateProps {}

const ApiKeyList: FC<ApiKeyListProps> = ({ reload, app }) => {
  const [items, setItems] = useState<ApiKey[]>()
  const [presentToast] = useIonToast()
  const [presetAlert] = useIonAlert()

  const fetchItems = async () => {
    const res: AxiosResponse = await AppApiService.Instance.get(`/v1/api-keys/${app?.publicId}`)
    if (res.status === 200) {
      setItems(res.data)
    } else {
      failure(`Failure ${res.status}`, presentToast)
    }
  }

  const deleteItem = async (itm: ApiKey) => {
    const res: AxiosResponse = await AppApiService.Instance.delete(`/v1/api-keys/${app?.publicId}/${itm?.publicId}`)
    if (res.status === 204) {
      await fetchItems()
    } else {
      failure(`Failure ${res.status}`, presentToast)
    }
  }

  useEffect(() => {
    if (!!app?.publicId) fetchItems()
  }, [reload, app?.publicId]) // eslint-disable-line

  return (
    <IonGrid>
      <h3 style={{ marginTop: '40px' }}>API Keys</h3>
      <IonRow className='tr-header'>
        <IonCol>Creation date</IonCol>
        <IonCol>Restrictions</IonCol>
        <IonCol>Actions</IonCol>
      </IonRow>
      {items?.map((itm: ApiKey) => (
        <IonRow key={itm.publicId}>
          <IonCol>{utcToLocaleDate(itm?.createdAt)}</IonCol>
          <IonCol></IonCol>
          <IonCol>
            <IonButton
              fill='clear'
              color='danger'
              onClick={() =>
                presetAlert({
                  header: 'Delete Api Key',
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                    },
                    {
                      text: 'Delete',
                      role: 'destructive',
                      handler: () => {
                        deleteItem(itm)
                      },
                    },
                  ],
                })
              }>
              <IonIcon icon={trashBin} />
            </IonButton>
          </IonCol>
        </IonRow>
      ))}
      <IonRow>
        <IonCol size='12'>
          No API Key to display
          <hr className='hr' />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: React.memo(ApiKeyList),
})
