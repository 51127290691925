import { success } from '../components/util/Toast'
import { connect } from '../data/connect'
import { App } from '../models/App'
import { Uptime } from '../models/uptime/Uptime'
import { UptimeService } from '../services/UptimeService'
import { IonButton, IonContent, IonFooter, IonToggle } from '@ionic/react'
import { IonInput, IonSelect, IonSelectOption } from '@ionic/react'
import { IonItem, IonLabel, IonToolbar, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import React, { FC, useState } from 'react'

interface OwnProps {
  onSuccess?: Function
  uptime?: Uptime
}
interface StateProps {
  app: App
}
interface DispatchProps {}
interface UptimeEditProps extends OwnProps, StateProps, DispatchProps {}

const UptimeEdit: FC<UptimeEditProps> = ({ onSuccess, app, uptime }) => {
  const [present] = useIonToast()
  const [item, setItem] = useState<Uptime | any>(uptime)
  const [wait, setWait] = useState(false)

  const save = async () => {
    setWait(true)
    try {
      const res: AxiosResponse | any = await UptimeService.Instance.post(
        '/v1/uptime',
        {
          id: item.id,
          appPublicId: app?.publicId,
          name: item.name,
          target: item.target,
          interval: item.interval,
          enabled: item.enabled,
        }
      )
      if (res.status === 201) {
        success('Uptime created successfully.', present)
      } else if (res.status === 200) {
        success('Uptime updated successfully.', present)
      }
      if (!!onSuccess) {
        onSuccess(item)
      }
    } catch (err: any) {
      present({ message: `${err.message}`, color: 'danger', duration: 3000 })
    }
    setWait(false)
  }

  return (
    <>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel className='required'>{t<string>('Name')}</IonLabel>
          <IonInput
            value={item?.name}
            onIonInput={(e) => setItem({ ...item, name: e.detail.value })}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel className='required'>{t<string>('Target')}</IonLabel>
          <IonInput
            value={item?.target}
            onIonChange={(e) => setItem({ ...item, target: e.detail.value })}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel className='required'>{t<string>('Interval')}</IonLabel>
          <IonSelect
            value={item?.interval}
            onIonChange={(e) => setItem({ ...item, interval: e.detail.value })}
          >
            <IonSelectOption value={60} disabled={true}>
              {t<string>('X minutes', { X: 1 })}
            </IonSelectOption>
            <IonSelectOption value={120} disabled={true}>
              {t<string>('X minutes', { X: 2 })}
            </IonSelectOption>
            <IonSelectOption value={300}>
              {t<string>('X minutes', { X: 5 })}
            </IonSelectOption>
            <IonSelectOption value={600}>
              {t<string>('X minutes', { X: 10 })}
            </IonSelectOption>
            <IonSelectOption value={1800}>
              {t<string>('X minutes', { X: 30 })}
            </IonSelectOption>
            <IonSelectOption value={3600}>
              {t<string>('X minutes', { X: 60 })}
            </IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>{t<string>('Enabled')}</IonLabel>
          <IonToggle
            checked={item?.enabled}
            onIonChange={(e) => setItem({ ...item, enabled: e.detail.checked })}
          ></IonToggle>
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar className='ion-margin-horizontal'>
          <IonButton
            onClick={save}
            disabled={wait || !item?.name || !item?.target || !item.target}
          >
            {t<string>('Save')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  mapDispatchToProps: {},
  component: React.memo(UptimeEdit),
})
