const currencySign = (currency?: string) => {
  switch (currency) {
    case 'IRR':
      return '﷼'
    case 'USD':
      return '$'
    case 'CAD':
      return 'C$'
  }
}

export default currencySign
