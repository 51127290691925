import React from 'react'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { PersonOption } from '../../pages-mfactor/person/PersonOption'
import { ActionType } from '../../util/types'
import { addFactorItemListData, getFactorData, removeFactorItemListData } from '../dataApi'
import { setFactorDateData, setShowScannerData } from '../dataApi'
import { setFactorItemListData, setFactorPayerData, setScanBeepData } from '../dataApi'
import { FactorState } from './factor.state'

export const loadFactorData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getFactorData()
  dispatch(setData(data))
}

export const setData = (data: Partial<FactorState>) =>
  ({
    type: 'set-factor-data',
    data,
  } as const)

export const setFactorItemList = (itemList: FactorItem[]) => async (dispatch: React.Dispatch<any>) => {
  const factorItemList = await setFactorItemListData(itemList)
  return {
    type: 'set-factor-item-list',
    factorItemList,
  } as const
}

export const addFactorItem = (factorItem: FactorItem) => async (dispatch: React.Dispatch<any>) => {
  const factorItemList = await addFactorItemListData(factorItem)
  return {
    type: 'set-factor-item-list',
    factorItemList,
  } as const
}

export const removeFactorItem = (factorItem: FactorItem) => async (dispatch: React.Dispatch<any>) => {
  const factorItemList = await removeFactorItemListData(factorItem)
  return {
    type: 'set-factor-item-list',
    factorItemList,
  } as const
}

export const addFactorItemFake = (factorItemList: FactorItem[]) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'add-factor-item',
    factorItemList,
  } as const
}

export const removeFactorItemFake = (factorItemList: FactorItem[]) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'remove-factor-item',
    factorItemList,
  } as const
}

export const setRefreshProductList = (refreshProductList: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-refresh-product-list',
    refreshProductList,
  } as const
}

export const setRefreshPersonList = (refreshPersonList: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-refresh-person-list',
    refreshPersonList,
  } as const
}
export const setRefreshFactorList = (refreshFactorList: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-refresh-factor-list',
    refreshFactorList,
  } as const
}

export const setSyncing = (syncing: number) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-syncing',
    syncing,
  } as const
}

export const setScanBeep = (scanBeep: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setScanBeepData(scanBeep)
  return {
    type: 'set-scan-beep',
    scanBeep,
  } as const
}

export const setShowScanner = (showScanner: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setShowScannerData(showScanner)
  return {
    type: 'set-show-scanner',
    showScanner,
  } as const
}

export const setFactorDate = (factorDate?: string) => async (dispatch: React.Dispatch<any>) => {
  await setFactorDateData(factorDate)
  return {
    type: 'set-factor-date',
    factorDate,
  } as const
}

export const setFactorPayer = (factorPayer?: PersonOption) => async (dispatch: React.Dispatch<any>) => {
  await setFactorPayerData(factorPayer)
  return {
    type: 'set-factor-payer',
    factorPayer,
  } as const
}

export const setFactorSaving = (factorSaving: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-factor-saving',
    factorSaving,
  } as const
}

export type FactorActions =
  | ActionType<typeof setData>
  | ActionType<typeof setFactorItemList>
  | ActionType<typeof addFactorItem>
  | ActionType<typeof addFactorItemFake>
  | ActionType<typeof removeFactorItem>
  | ActionType<typeof removeFactorItemFake>
  | ActionType<typeof setRefreshProductList>
  | ActionType<typeof setRefreshPersonList>
  | ActionType<typeof setRefreshFactorList>
  | ActionType<typeof setSyncing>
  | ActionType<typeof setScanBeep>
  | ActionType<typeof setFactorDate>
  | ActionType<typeof setFactorPayer>
  | ActionType<typeof setFactorSaving>
  | ActionType<typeof setShowScanner>
