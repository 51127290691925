import { t } from 'i18next'
import Modal from '../components/Modal'
import ProductAddButton from './ProductAddButton'
import ProductList from './ProductList'

const ProductListModal = () => {
  return (
    <Modal
      id='product-list'
      onDidDismiss={() => {}}
      trigger='open-product-list'
      title={t<string>('Product list')}
      actionButtonsEnd={<ProductAddButton onDismiss={() => {}} />}>
      <ProductList />
    </Modal>
  )
}

export default ProductListModal
