import React, { FC, useState } from 'react'
import { IonButton, IonFabButton, IonIcon } from '@ionic/react'
import { logIn } from 'ionicons/icons'
import { Auth } from '../../services/AuthService'

interface SignInButtonProps {
  fab?: boolean
}
const SignInButton: FC<SignInButtonProps> = ({ fab = false }) => {
  const [inprogress, setInprogress] = useState(false)

  const handleSignIn = async (e: any) => {
    setInprogress(true)
    e.preventDefault()
    try {
      await Auth.Instance.signIn()
        .then()
        .catch(() => {})
    } catch (ignore) {}
    setInprogress(false)
  }

  return (
    <>
      {fab ? (
        <IonFabButton onClick={handleSignIn} disabled={inprogress} title='Login'>
          <IonIcon icon={logIn}></IonIcon>
        </IonFabButton>
      ) : (
        <IonButton className='ion-margin-bottom' onClick={handleSignIn} disabled={inprogress}>
          Sign in
        </IonButton>
      )}
    </>
  )
}

export default React.memo(SignInButton)
