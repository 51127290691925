import React, { FC, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonChip, IonIcon, IonItem } from '@ionic/react'
import { IonItemOption, IonItemOptions, IonItemSliding, IonLabel } from '@ionic/react'
import { IonList, useIonAlert, useIonToast } from '@ionic/react'
import { paperPlane, trashBin } from 'ionicons/icons'
import { AxiosResponse } from 'axios'
import { utcToLocale } from '../../components/util/Date'
import { TResponse } from '../../components/util/TResponse'
import { failure, success } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setNet } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { User } from '../../models/User'
import { WebPush } from '../../models/WebPush'
import { AppApiService } from '../../services/AppApiService'
import PageContainer from '../PageContainer'

interface OwnProps {
  reload?: boolean
}
interface StateProps {
  app?: App
  user?: User
}
interface DispatchProps {
  setNet: typeof setNet
}
interface AdminWebPushListProps extends OwnProps, StateProps, DispatchProps {}

const AdminWebPushList: FC<AdminWebPushListProps> = ({ reload, app, user, setNet }) => {
  const [items, setItems] = useState<WebPush[]>()
  const [presentToast] = useIonToast()
  const [presetAlert] = useIonAlert()
  const [testing, setTesting] = useState(false)

  const fetchItems = async () => {
    setNet(true)
    if (user?.sub) {
      const res: AxiosResponse = await AppApiService.Instance.get(`/v1/admin/web-push/all`)
      if (res.status === 200) {
        setItems(res.data)
      } else {
        failure(`Failure ${res.status}`, presentToast)
      }
    }
    setNet(false)
  }

  const deleteItem = async (itm: WebPush) => {
    setNet(true)
    const res: TResponse = await AppApiService.Instance.delete(`/v1/admin/web-push/${itm?.publicId}`)
    if (res.status === 204) {
      await fetchItems()
    } else {
      failure(`Failure ${res.status}`, presentToast)
    }
    setNet(false)
  }

  const testItem = async (itm: WebPush) => {
    setNet(true)
    setTesting(true)
    const res: TResponse = await AppApiService.Instance.post(`/v1/admin/web-push/send/${itm.publicId}`, {
      publicId: itm.publicId,
      payload: 'Hello Taakestan!',
    })
    if (res.status === 201) {
      success('Success sending web push', presentToast)
    } else {
      presentToast({
        message: `Status ${res.status}`,
        color: res.status !== 410 ? 'tertiary' : 'danger',
        duration: 3000,
      })
    }
    setTesting(false)
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
  }, [reload]) // eslint-disable-line

  return (
    <PageContainer id='admin-web-push' title='Admin Web Push'>
      <IonList>
        <h3 style={{ marginTop: '40px' }}>Web Push</h3>
        {items?.map((itm: WebPush) => (
          <IonItemSliding key={itm.publicId}>
            <IonItem>
              <IonLabel className='ion-text-wrap'>
                {itm.deviceId?.split('::')?.map((itm: string) => (
                  <IonChip key={itm}>{itm}</IonChip>
                ))}
                <p>{utcToLocale(itm?.createdAt)}</p>
              </IonLabel>
              <IonButtons slot='end'>
                <IonButton fill='clear' onClick={() => testItem(itm)} disabled={testing}>
                  <IonIcon icon={paperPlane} slot='icon-only' />
                </IonButton>
              </IonButtons>
            </IonItem>
            <IonItemOptions>
              <IonItemOption
                color={'danger'}
                onClick={() =>
                  presetAlert({
                    header: 'Delete Web Push',
                    buttons: [
                      {
                        text: 'Cancel',
                        role: 'cancel',
                      },
                      {
                        text: 'Delete',
                        role: 'destructive',
                        handler: () => {
                          deleteItem(itm)
                        },
                      },
                    ],
                  })
                }>
                <IonIcon icon={trashBin} slot='bottom' />
                Delete
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
        {items?.length === 0 && (
          <IonItem>
            <IonLabel>No record to display</IonLabel>
          </IonItem>
        )}
      </IonList>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    user: state.user.user,
  }),
  mapDispatchToProps: {
    setNet,
  },
  component: React.memo(AdminWebPushList),
})
