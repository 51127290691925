import Modal from '../../components/Modal'
import { log } from '../../components/util/Log'
import CertificateInput from './CertificateInput'
import EconomicCodeInput from './EconomicCodeInput'
import MemoryIdInput from './MemoryIdInput'
import NationalIdInput from './NationalIdInput'
import PrivateKeyInput from './PrivateKeyInput'
import { IonCard, IonCardContent, IonSegment } from '@ionic/react'
import { IonSegmentButton } from '@ionic/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

const segments = ['TAX']

const SettingsModal = () => {
  const [segment, setSegment] = useState<string>('TAX')

  useEffect(() => {
    log(`[${segment}]`)
  }, [segment])

  return (
    <Modal
      id='settings-modal'
      onDidDismiss={() => {}}
      trigger='open-settings'
      title={t<string>('Settings')}
    >
      <div>
        <IonSegment
          value={segment || 'TAX'}
          onIonChange={(e: any) => {
            if (segments.includes(e.detail.value)) setSegment(e.detail.value)
          }}
        >
          <IonSegmentButton value={'TAX'}>{t<string>('Tax')}</IonSegmentButton>
          {segment === 'TAX' && (
            <IonCard>
              <IonCardContent>
                <NationalIdInput />
                <EconomicCodeInput />
                <MemoryIdInput />
                <PrivateKeyInput />
                <CertificateInput />
              </IonCardContent>
            </IonCard>
          )}
        </IonSegment>
      </div>
    </Modal>
  )
}

export default SettingsModal
