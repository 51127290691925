import { connect } from '../../data/connect'
import WebPushToggle from '../account/WebPushToggle'
import WebPushSubscribeButton from './WebPushSubscribeButton'
import { IonChip, IonCol, IonGrid, IonRow } from '@ionic/react'
import React, { FC } from 'react'

interface OwnProps {
  onSuccess?: (data: any) => void
}
interface StateProps {
  webPush: boolean
}
interface WebPushSubscribeProps extends OwnProps, StateProps {}
const WebPushSubscribe: FC<WebPushSubscribeProps> = ({
  webPush,
  onSuccess,
}) => {
  return (
    <IonGrid>
      {!('Notification' in window) && (
        <IonChip color='danger'>Notifications aren't supported.</IonChip>
      )}
      {!('PushManager' in window) && (
        <IonChip color='danger'>Push messaging isn't supported.</IonChip>
      )}
      {!webPush && (
        <>
          <IonRow>
            <IonCol>
              <IonChip color='warning'>Please enable web push first</IonChip>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <WebPushToggle />
            </IonCol>
          </IonRow>
        </>
      )}
      {webPush && (
        <>
          <IonRow>
            <IonCol>
              <WebPushSubscribeButton
                onSuccess={(data: any) => {
                  if (!!onSuccess) onSuccess(data)
                }}
              />
            </IonCol>
          </IonRow>
        </>
      )}
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    webPush: state.data.webPush,
  }),
  component: React.memo(WebPushSubscribe),
})
