import { Database, Q } from '@nozbe/watermelondb'
import { synchronize } from '@nozbe/watermelondb/sync'
import { AxiosResponse } from 'axios'
import { log } from '../components/util/Log'
import { convertToEnglish } from '../components/util/Number'
// const { mfactordb } = lazily(() => import('../data/mfactor/mfactordb'))
import { mfactordb } from '../data/mfactor/mfactordb'
import Factor from '../models/mfactor/Factor'
import Person from '../models/mfactor/Person'
import { GtinDbService } from './GtinDbService'
import { MFactorService } from './MFactorService'

export class MFactorDbService {
  private static mFactorDbService: MFactorDbService | undefined
  public db: Database
  public personCache

  constructor(mfactorDb: Database) {
    this.db = mfactorDb
    this.personCache = new Map()
  }

  async personList(appPublicId: string, search?: string) {
    return this.db.read(() => {
      const whereClause = []
      whereClause.push(Q.where('app_public_id', appPublicId))
      if (search)
        whereClause.push(Q.and(Q.or(Q.where('name', Q.like(`%${search}%`)), Q.where('detail', Q.like(`%${search}%`)))))
      return this.db
        ?.get<Person>('person')
        ?.query(...whereClause, Q.sortBy('update_at', Q.desc), Q.take(10))
        ?.fetch()
    })
  }

  async searchFactor(appPublicId: string, skip: number, search?: string) {
    return this.db.read(async () => {
      const whereClause = []
      whereClause.push(Q.experimentalJoinTables(['person']))
      whereClause.push(Q.where('app_public_id', appPublicId || ''))
      if (search) {
        const productIds = await GtinDbService.Instance.fetchProductIds(appPublicId, search)
        const productClause: any = []
        productIds
          .map((pid) => pid.split('_')[1])
          .forEach((gtin) => {
            productClause.push(Q.where('detail', Q.like(`%${gtin}%`)))
          })
        whereClause.push(
          Q.and(
            Q.or(
              Q.where('total', Q.like(`%${convertToEnglish(search)}%`)),
              Q.on('person', 'name', Q.like(`%${search}%`)),
              ...productClause
            )
          )
        )
      }
      return this.db
        ?.get<Factor>('factor')
        ?.query(...whereClause, Q.sortBy('created_at', Q.desc), Q.skip(skip), Q.take(5))
        ?.fetch()
    })
  }

  async lookupPersonName(personId?: string, appPublicId?: string): Promise<string> {
    if (personId) {
      if (this.personCache.has(personId)) return Promise.resolve(this.personCache.get(personId))
      const person: Person | null = await this.db.read(async () => {
        return await this.db
          .get<Person>('person')
          .find(personId)
          .catch(() => null)
      })
      if (person?.name) {
        log('lookupPersonNamePutFromDbInCache', personId, person.name)
        this.personCache.set(personId, person.name)
        return Promise.resolve(person.name)
      } else {
        const remotePerson = await MFactorService.Instance.get(`/v1/person/${appPublicId}/${personId}`)
        if (remotePerson?.status === 200) {
          log('lookupPersonNamePutFromRemoteInCache', personId, remotePerson.data)
          this.personCache.set(personId, remotePerson.data)
          return Promise.resolve(remotePerson.data)
        } else return Promise.resolve('...')
      }
    } else return Promise.resolve('-')
  }

  async syncDb(appPublicId: string) {
    await synchronize({
      database: this.db,
      pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
        const urlParams = `last_pulled_at=${lastPulledAt}&schema_version=${schemaVersion}&migration=${encodeURIComponent(
          JSON.stringify(migration)
        )}`
        const response: AxiosResponse = await MFactorService.Instance.get(`/v1/factor/sync/${appPublicId}?${urlParams}`)
        const { changes, timestamp } = response.data
        return { changes, timestamp }
      },
      pushChanges: async ({ changes, lastPulledAt }) => {
        const res = await MFactorService.Instance.post(
          `/v1/factor/sync/${appPublicId}?last_pulled_at=${lastPulledAt}`,
          {
            changes,
          }
        )
      },
      migrationsEnabledAtVersion: 1,
      onWillApplyRemoteChanges: (info) => {
        return Promise.resolve()
      },
    })
  }

  private static buildDbInstance() {
    return new MFactorDbService(mfactordb)
  }

  public static get Instance(): MFactorDbService {
    if (!this.mFactorDbService) {
      this.mFactorDbService = this.buildDbInstance()
    }

    return this.mFactorDbService
  }
}
