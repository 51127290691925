import React, { FC, useRef } from 'react'
import { IonButton, IonButtons, IonContent, IonHeader, IonModal } from '@ionic/react'
import { IonProgressBar, IonTitle, IonToolbar } from '@ionic/react'
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import { t } from 'i18next'
import { connect } from '../data/connect'
import { mainSlot, minorSlot } from './util/resolveSlot'

interface OwnProps {
  id: string
  isOpen?: boolean
  onDidDismiss: (event: CustomEvent<OverlayEventDetail>) => void
  trigger?: string
  title?: string
  usePadding?: boolean
  actionButtonsEnd?: any
  children?: any
  mode?: 'ios' | 'md'
}
interface StateProps {
  hand: 'right' | 'left'
  syncing: number
}
interface ModalProps extends OwnProps, StateProps {}
const Modal: FC<ModalProps> = ({
  id,
  isOpen,
  usePadding = true,
  children,
  onDidDismiss,
  title = '',
  actionButtonsEnd,
  hand,
  mode,
  syncing,
  trigger,
}) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const dir = document.documentElement.dir

  return (
    <IonModal ref={modal} id={`${id}-modal`} isOpen={isOpen} onDidDismiss={onDidDismiss} mode={mode} trigger={trigger}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, dir)}>
            <IonButton onClick={() => modal.current?.dismiss()}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot={mainSlot(hand, dir)}>{actionButtonsEnd}</IonButtons>
          {!!syncing && <IonProgressBar value={syncing}></IonProgressBar>}
        </IonToolbar>
      </IonHeader>
      <IonContent className={usePadding ? 'ion-padding' : ''}>{children}</IonContent>
    </IonModal>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
    syncing: state.factor.syncing,
  }),
  component: React.memo(Modal),
})
