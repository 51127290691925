import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { ActionSheetButton } from '@ionic/core'
import { IonIcon, IonHeader, IonToolbar, IonActionSheet } from '@ionic/react'
import { IonButtons, IonContent, IonButton, IonBackButton, IonPage } from '@ionic/react'
import { shareOutline, shareSharp } from 'ionicons/icons'
import { t } from 'i18next'
import { log } from '../../../components/util/Log'
import { connect } from '../../../data/connect'
import * as selectors from '../../../data/selectors'
import { Product } from '../../../models/Product'
import './ProductDetail.scss'
import ProductMFactor from './ProductMFactor'
import ProductWebPush from './ProductWebPush'

interface OwnProps extends RouteComponentProps {
  product?: Product
}

interface StateProps {}

interface DispatchProps {}

interface ProductDetailProps extends OwnProps, StateProps, DispatchProps {}

const ProductDetail: React.FC<ProductDetailProps> = ({ product }) => {
  const [showActionSheet, setShowActionSheet] = useState(false)
  const [actionSheetButtons, setActionSheetButtons] = useState<ActionSheetButton[]>([])
  const [actionSheetHeader, setActionSheetHeader] = useState('')

  function openProductShare(product: Product) {
    setActionSheetButtons([
      {
        text: 'Copy Link',
        handler: () => {
          log('Copy Link clicked')
        },
      },
      {
        text: 'Share via ...',
        handler: () => {
          log('Share via clicked')
        },
      },
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          log('Cancel clicked')
        },
      },
    ])
    setActionSheetHeader(`Share ${product.name}`)
    setShowActionSheet(true)
  }

  if (!product) {
    return <div>Product not found</div>
  }

  const productAbout = (product: any) => (
    <>
      {product.id === 'web-push' ? (
        <ProductWebPush />
      ) : product.id === 'm-factor' ? (
        <ProductMFactor />
      ) : (
        <p>{t<string>(`${product.about}`)} </p>
      )}
    </>
  )

  return (
    <IonPage id='product-detail'>
      <IonContent>
        <IonHeader className='ion-no-border'>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton defaultHref='/products' />
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton onClick={() => openProductShare(product)} disabled={true}>
                <IonIcon slot='icon-only' ios={shareOutline} md={shareSharp}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className='product-background'>
          <img src={product.pic} alt={product.name} />
          <h2>{product.name}</h2>
        </div>

        <div className='ion-padding product-detail'>
          <h3>{t<string>(`${product.title}`)}</h3>
          {productAbout(product)}
          <hr />
        </div>
      </IonContent>
      <IonActionSheet
        isOpen={showActionSheet}
        header={actionSheetHeader}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={actionSheetButtons}
      />
    </IonPage>
  )
}

export default connect({
  mapStateToProps: (state, ownProps) => ({
    product: selectors.getProduct(state, ownProps),
  }),
  component: React.memo(ProductDetail),
})
